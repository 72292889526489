import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Arrow } from 'icons/arrowback.svg';
import { Details } from 'components/Details/Details';
import { Loader } from 'components/Loader/Loader';
import { getOneById } from '../redux/slices/actions/operations';
import { setAuthHeader } from '../api.js';
import { daysRemaining } from 'helpers/daysRemaining';
import css from './Action.module.scss';

const content = {
  ru: {
    remain: 'Осталось',
    days: 'бесплатного пользования!!!',
    paid: 'Вы оплатили пакет',
    remaining: 'Остается',
    time: 'дней пользования!!!',
  },
  uk: {
    remain: 'Залишилось',
    days: 'безкоштовного користування!!!',
    paid: 'Ви оплатили пакет',
    remaining: 'Залишається',
    time: 'днів користування!!!',
  },
  en: {
    remain: 'Remaining',
    days: 'of free usage!!!',
    paid: 'You have paid for the package',
    remaining: 'Remaining',
    time: 'days of usage!!!',
  },
};

const Action = () => {
  const [period, setPeriod] = useState('today');
  const [spot, setSpot] = useState('all');

  const { id = '' } = useParams();

  const { token, lang } = useSelector(state => state.auth);
  const { createdAt } = useSelector(state => state.auth.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (id && period !== 'date' && token) {
      setAuthHeader(token);
      dispatch(getOneById({ id, period, spot }));
    }
  }, [id, period, token, spot, dispatch]);

  const { action } = useSelector(state => state.actions);

  const selectedPeriodHandler = value => {
    setPeriod(value);
  };

  const selectedSpotHandler = value => {
    setSpot(value);
  };

  return Object.values(action)?.length > 0 ? (
    <>
      <p className={css.notify}>
        {`${content[lang].remain} ${daysRemaining(createdAt)} ${
          content[lang].time
        }`}
      </p>
      <Link to="/actions" className="link" title="back">
        <Arrow />
      </Link>
      <Details
        action={action}
        selectedPeriod={selectedPeriodHandler}
        selectedSpot={selectedSpotHandler}
      />
    </>
  ) : (
    <Loader />
  );
};

export default Action;
