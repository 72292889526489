import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from 'components/Header/Header';
import { Loader } from 'components/Loader/Loader';
import { GrInstagram } from 'react-icons/gr';
import css from './SharedLayout.module.scss';

export const SharedLayout = () => {
  return (
    <div className={css.container}>
      <Header />
      <main className={css.wrapper}>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </main>
      <footer className={css.foot}>
        <div className={css.cont}>
          <p className={css.contacts}>loyal2yourbusiness &copy;</p>
          <p className={css.contacts}>All rights reserved.</p>
          <p className={css.contacts}>admin.loyal2yourbusiness@proton.me</p>
        </div>
        <a
          className={css.link}
          href="https://www.instagram.com/loyal2yourbusiness?igsh=MTg2aWN1bHZocWpzbA%3D%3D&utm_source=qr"
          target="blank"
          title="instagram link"
        >
          <GrInstagram />
        </a>
      </footer>
    </div>
  );
};
