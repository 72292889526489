import { QRCodeSVG } from 'qrcode.react';
import css from './GiftQR.module.scss';

const content = {
  ru: {
    text: 'Покажите этот QR-код сотруднику торговой точки, чтобы получить подарок!',
  },
  uk: {
    text: 'Покажіть цей QR-код співробітнику торгової точки, щоб отримати подарунок!',
  },
  en: {
    text: 'Show this QR-code to employee of the trade spot to receive the gift!',
  },
};

export const GiftQR = ({ promo, actionId, lang }) => {
  const link = `https://loyal2yourbusiness.com/actions/${actionId}/checkpromo/${promo}`;

  return (
    <div className={css.container}>
      <p className={css.title}>{content[lang].text}</p>
      <div className={css.wrapper}>
        <QRCodeSVG value={link} size={'100%'} />
      </div>
    </div>
  );
};
