import { useState, forwardRef } from 'react';
import { registerLocale } from 'react-datepicker';
import DatePicker from 'react-datepicker';
import { ru } from 'date-fns/locale/ru';

import 'react-datepicker/dist/react-datepicker.css';
import css from './DateSelector.module.scss';

export const DateSelector = ({ onSelect, initialDate = null, minDate }) => {
  const [selected, setSelected] = useState(initialDate || new Date());

  registerLocale('ru', ru);

  const today = new Date();
  const highlightDates = [today];

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className={css.field} onClick={onClick} ref={ref} type="button">
      {value}
    </button>
  ));

  const selectHandler = value => {
    setSelected(value);
    onSelect(value);
  };

  return (
    <div className={css.container}>
      <DatePicker
        selected={selected}
        locale="ru"
        onChange={selectHandler}
        dateFormat="dd-MM-yyyy"
        minDate={minDate}
        maxDate={new Date()}
        customInput={<ExampleCustomInput />}
        highlightDates={highlightDates}
        placeholderText="Выберити дату"
        withPortal
      />
    </div>
  );
};
