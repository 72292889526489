import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as Ok } from 'icons/point.svg';
import { ReactComponent as Lamp } from 'icons/lamp.svg';
import { ReactComponent as Arrow } from 'icons/arrowback.svg';
import { getPlanDays } from '../helpers/getPlanDays';
import { getPayment } from '../redux/slices/auth/operations';
import { setLink } from '../redux/slices/auth/slice';
import css from './Payment.module.scss';

const data = [
  { name: 'basic', price: '10$', spots: 5, actions: 5, amount: 10 },
  { name: 'standart', price: '18$', spots: 10, actions: 5, amount: 18 },
  { name: 'extra', price: '25$', spots: 30, actions: 5, amount: 25 },
  { name: 'mega', price: '40$', spots: 300, actions: 10, amount: 40 },
];

const content = {
  ru: {
    paid: 'У вас оплачен пакет',
    termination: 'Окончание срока действия',
    available: 'Доступно:',
    actions: 'Акций:',
    spots: 'Торговых точек:',
    package: 'пакет',
    includes: 'Пакет включает:',
    pay: 'Оплатить',
    absent: 'У вас пока не оплачен ни один из пакетов',
    before: 'до',
    actionWord: 'акций',
    spotsWord: 'торговых точек',
    notify: 'В настоящее время доступен только способ оплаты криптовалютой.',
  },
  uk: {
    paid: 'У вас оплачено пакет',
    termination: 'Завершення терміну дії',
    available: 'Доступно:',
    actions: 'Акцій:',
    spots: 'Торгових точок:',
    package: 'пакет',
    includes: 'Пакет включає:',
    pay: 'Сплатити',
    absent: 'Жоден із пакетів ще не оплачено',
    before: 'до',
    actionWord: 'акцій',
    spotsWord: 'торгових точок',
    notify: 'Наразі доступний лише спосіб оплати криптовалютою',
  },
  en: {
    paid: 'You have paid package',
    termination: 'Expiration date',
    available: 'Available:',
    actions: 'Promotions:',
    spots: 'Trade spots:',
    package: 'package',
    includes: 'Package includes:',
    pay: 'Pay',
    absent: 'None of the packages have been paid for yet',
    before: 'up to',
    actionWord: 'promotions',
    spotsWord: 'trade spots',
    notify: 'Currently, only cryptocurrency payment is available',
  },
};

const Payment = () => {
  const {
    plan = '',
    createdAt = '',
    freeSpots = '',
    freeActions = '',
  } = useSelector(state => state.auth.user);
  const { lang, link } = useSelector(state => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (link) {
      window.location.href = link;
      dispatch(setLink(null));
    }
  }, [link, dispatch]);

  const paymentHandler = async (amount, name) => {
    dispatch(getPayment({ amount, name }));
  };

  return (
    <>
      <Link to="/profile" className="link" title="back">
        <Arrow />
      </Link>
      <div className={css.container}>
        <div className={css.wrapper}>
          {plan ? (
            <p className={css.header}>
              {content[lang].paid} <span>{plan}</span>
            </p>
          ) : (
            <p className={css.header}>{content[lang].absent}</p>
          )}

          {plan && (
            <p className={css.date}>
              {`${content[lang].termination} ${getPlanDays(createdAt)}`}
            </p>
          )}

          <p className={css.availableTitle}>{content[lang].available}</p>

          <span className={css.available}>
            {`${content[lang].actions} ${freeActions}`}
          </span>

          <span className={css.available}>
            {`${content[lang].spots} ${freeSpots}`}
          </span>
        </div>

        <p className={css.notify}>
          <Lamp />
          {content[lang].notify}
        </p>
        <ul className={css.list}>
          {data.map(({ name, price, spots, actions, amount }) => (
            <li className={css.item} key={name}>
              <p className={css.name}>
                <span className={css.pack}>{content[lang].package}</span>
                {name}
              </p>
              <span className={css.price}>
                {price}
                <span className={css.day}> / 30 days</span>
              </span>
              <p className={css.title}>{content[lang].includes}</p>
              <span className={css.stats}>
                <Ok />
                {`${content[lang].before} ${actions} ${content[lang].actionWord}`}
              </span>
              <span className={css.stats}>
                <Ok />
                {`${content[lang].before} ${spots} ${content[lang].spotsWord}`}
              </span>
              <button
                type="button"
                className={css.btn}
                onClick={() => paymentHandler(amount, name)}
              >
                {content[lang].pay}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Payment;
