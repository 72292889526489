import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Arrow } from 'icons/arrowback.svg';
import { useDispatch, useSelector } from 'react-redux';
import { EmployeesList } from 'components/EmployeesList/EmployeesList';
import { EmployeeForm } from 'components/EmployeeForm/EmployeeForm';
import { getAllEmployees } from '../redux/slices/auth/operations';
import { setAuthHeader } from '../api.js';

const Employees = () => {
  const { employees, token } = useSelector(state => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      setAuthHeader(token);
      dispatch(getAllEmployees());
    }
  }, [dispatch, token]);

  return (
    <>
      <Link to="/profile" className="link" title="back">
        <Arrow />
      </Link>
      <EmployeesList list={employees} />
      <EmployeeForm />
    </>
  );
};

export default Employees;
