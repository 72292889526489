import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LiaCopy } from 'react-icons/lia';
import { MdDelete } from 'react-icons/md';
import { FiRefreshCw } from 'react-icons/fi';
import { MdLocationPin } from 'react-icons/md';
import { ReactComponent as Info } from 'icons/info.svg';
import { Modal } from 'components/Modal/Modal';
import { toTitleCase } from '../../helpers/toTitleCase';
import { setStatus, setMessage } from '../../redux/slices/auth/slice';
import {
  refreshToken,
  deleteEmployee,
} from '../../redux/slices/auth/operations';
import { setIsInformed } from '../../redux/slices/auth/slice';
import css from './EmployeesList.module.scss';

const content = {
  ru: {
    confirm: 'Вы уверены, что хотите удалить сотрудника',
    warning:
      'Пожалуйста, обратите внимание, что ваши сотрудники будут иметь доступ только к QR-коду акции, которую вы определите для каждого из них. Они не смогут как-либо редактировать ваши акции или видеть их статистику. Вы всегда сможете отозвать доступ каждого из них к вашим акциям, нажав кнопку сгенерировать новый токен.',
    copy: 'Токен скопирован!',
    employees: 'Ваши сотрудники:',
    token: 'Токен для входа в систему',
    pass: 'передайте этот токен вашему сотруднику для входа в систему',
    absent:
      'Для этого сотрудника пока не назначена ни одна торговая точка или торговая точка этого сотрудника не участвует ни в одной из акций.',
    noEmployee: 'У вас пока нет сотрудников',
  },
  uk: {
    confirm: 'Ви впевнені, що хочете видалити співробітника',
    warning:
      'Будь ласка, зверніть увагу, що ваші співробітники матимуть доступ лише до QR-коду акції, яку ви визначите для кожного з них. Вони не зможуть ніяк редагувати ваші акції або бачити їх статистику. Ви завжди зможете відкликати доступ кожного з них до ваших акцій, натиснувши кнопку згенерувати новий токен.',
    copy: 'Токен скопійовано!',
    employees: 'Ваші співробітники:',
    token: 'Токен для входу в систему ',
    pass: 'передайте цей токен вашому співробітнику для входу в систему',
    absent:
      'Цьому співробітнику поки не призначена жодна торгова точка, або торгова точка цього працівника не бере участі у жодній з акцій.',
    noEmployee: 'У вас поки немає співробітників',
  },
  en: {
    confirm: 'Are you sure you want to delete employee',
    warning:
      'Please note that your employees will only have access to the QR code of the promotion you define for each of them. They will not be able to edit your promotions in any way or see their statistics. You can always revoke each of their access to your promotions by clicking the generate new token button.',
    copy: 'Token copied!',
    employees: 'Your employees:',
    token: 'Token for system login',
    pass: 'give this token to your employee for system login',
    absent:
      'This employee is currently not assigned to any trade spot, or the trade spot of this employee does not participate in any of the promotions.',
    noEmployee: "You don't have any employees yet",
  },
};

export const EmployeesList = ({ list }) => {
  const [name, setName] = useState('');
  const [employeeId, setEmployeeId] = useState('');

  const { isInformed, lang } = useSelector(state => state.auth);

  const dispatch = useDispatch();

  const refreshTokenHandler = id => {
    dispatch(refreshToken({ id }));
  };

  const deleteEmployeeHandler = () => {
    dispatch(deleteEmployee({ id: employeeId }));
    setName('');
  };

  const deleteSelectedEmployeeHandler = (id, name) => {
    setEmployeeId(id);
    setName(name);
    localStorage.removeItem('employeeForm');
  };

  const copyToClipboard = async token => {
    await navigator.clipboard.writeText(token);
    dispatch(setMessage(content[lang].copy));
    dispatch(setStatus('success'));
  };

  const closeInfoHandler = async () => {
    dispatch(setIsInformed(true));
  };

  return (
    <>
      <Modal
        onOpen={name}
        text={`${content[lang].confirm} ${name}?`}
        onClose={() => setName('')}
        onClick={deleteEmployeeHandler}
      />
      <p className={css.header}>{content[lang].employees}</p>
      {list?.length > 0 ? (
        <>
          <div
            className={
              isInformed ? `${css.warning}` : `${css.warning} ${css.hidden}`
            }
          >
            <Info />
            <span>{content[lang].warning}</span>
            <button
              type="button"
              onClick={closeInfoHandler}
              className={`${css.btn} ${css.info}`}
            >
              ОК
            </button>
          </div>
          <ul className={css.list}>
            {list.map(({ _id, name, token, spot }) => (
              <li className={css.item} key={_id}>
                <p className={css.title}>
                  {toTitleCase(name)}
                  {spot?.adress && (
                    <span className={css.location}>
                      <MdLocationPin />
                      {toTitleCase(spot?.adress)}
                    </span>
                  )}
                </p>
                {spot?.actions?.length > 0 ? (
                  <label className={css.promoWrapper}>
                    <span className={css.token}>{content[lang].token}</span>
                    <span className={css.info}>
                      <Info /> {content[lang].pass}
                    </span>
                    <input className={css.promo} value={token} readOnly />
                    <button
                      type="button"
                      className={css.copy}
                      onClick={() => copyToClipboard(token)}
                      title="copy token"
                    >
                      <LiaCopy />
                    </button>
                  </label>
                ) : (
                  <p className={css.inactive}>{content[lang].absent}</p>
                )}
                <div className={css.btnWrapper}>
                  {spot && spot?.actions?.length > 0 && (
                    <button
                      className={css.btn}
                      type="button"
                      onClick={() => refreshTokenHandler(_id)}
                      title="generate a new token"
                    >
                      <FiRefreshCw />
                    </button>
                  )}
                  <button
                    className={`${css.btn} ${css.delete}`}
                    type="button"
                    onClick={() => deleteSelectedEmployeeHandler(_id, name)}
                    title="delete"
                  >
                    <MdDelete />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <p className={css.absent}>{content[lang].noEmployee}</p>
      )}
    </>
  );
};
