export const daysRemaining = dateString => {
  const startDate = new Date(dateString);

  const now = new Date();

  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 31);

  const remainingTime = endDate - now;

  const remainingDays = Math.max(
    0,
    Math.floor(remainingTime / (1000 * 60 * 60 * 24))
  );

  return remainingDays;
};
