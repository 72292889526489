import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage, setStatus } from '../../redux/slices/auth/slice';
import { ReactComponent as OK } from 'icons/confirm.svg';
import { ReactComponent as ERROR } from 'icons/cancel.svg';
import {
  setActionsMessage,
  setActionsStatus,
} from '../../redux/slices/actions/slice';
import css from './Popup.module.scss';

export const Popup = () => {
  const { message = '', status = '' } = useSelector(state => state.auth);
  const actionsMessage = useSelector(state => state.actions.message);
  const actionsStatus = useSelector(state => state.actions.status);

  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setMessage(''));
      dispatch(setStatus(''));
      dispatch(setActionsMessage(''));
      dispatch(setActionsStatus(''));
    }, 3500);

    // Clear timeout if the component is unmounted before the timer ends
    return () => clearTimeout(timer);
  }, [message, status, actionsMessage, actionsStatus, dispatch]);

  return (
    <>
      {message && status && (
        <div
          className={
            message && status ? `${css.wrapper} ${css.shown}` : `${css.wrapper}`
          }
          style={{ color: status === 'error' ? '#fe3d3d' : '#02AE1E' }}
        >
          {status === 'error' ? <ERROR /> : <OK />} {message}
        </div>
      )}
    </>
  );
};
