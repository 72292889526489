import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const PrivateRoute = ({ component: Component }) => {
  const { isLoggedIn } = useSelector(state => state.auth);
  const { isAuthenticated } = useSelector(state => state.actions);

  return !isLoggedIn ? (
    <Navigate to={isAuthenticated ? '/employees/profile' : '/login'} />
  ) : (
    Component
  );
};
