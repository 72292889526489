import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { Select } from '../Select/Select';
import { LangSelect } from 'components/LangSelect/LangSelect';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { ReactComponent as Ok } from 'icons/ok.svg';
import { emailPattern, inputPattern, passwordPattern } from 'helpers/patterns';
import { setEmail } from '../../redux/slices/auth/slice';
import { register, login } from '../../redux/slices/auth/operations';
import { authenticate } from '../../redux/slices/actions/operations';
import { setLang } from '../../redux/slices/auth/slice';
import { getUserLang } from 'helpers/getUserLang';
import css from './RegisterForm.module.scss';

const content = {
  uk: {
    format: '* Введіть дані в потрібному форматі',
    email:
      '* Адреса електронної пошти має містити символ @ і закінчуватись назвою домену після крапки',
    required: "* Обов'язкове поле",
    min: '* Мінімум 8 символів',
    max: '* Максимум 16 символів',
    pass: '* Пароль повинен складатись з велеких та малих латинських літер та цифр',
    token: 'Токен повинен бути без заборонених символів',
    enter: 'Вхід',
    registration: 'Регістрація',
    register: 'Зареєструватись?',
    login: 'Увійти?',
    forget: 'Забули пароль?',
    tokenTitle: 'уведіть токен для входу',
    placeholder: 'від 8 до 16 символів',
    owner: 'Як власник бізнесу',
    latin: 'Лише латинські літери',
    upper: 'Хоча б 1 велика літера',
    lower: 'Хоча б 1 маленька літера',
    digit: 'Хоча б 1 цифра',
    employee: 'Як співробітник',
    emailTitle: 'Пошта',
    passTitle: 'Пароль',
  },
  ru: {
    format: '* Введите данные в нужном формате',
    email:
      '* Адрес электронной почты должен содержать символ @ и заканчиваться названием домена после точки',
    required: '* Обязательное поле',
    min: '* Минимум 8 символов',
    max: '* Максимум 16 символов',
    pass: '* Пароль должен содержать большие и маленькие латинские буквы и цифры',
    token: 'Токен должен быть без запрещенных символов',
    enter: 'Вход',
    registration: 'Регистрация',
    register: 'Зарегистрироваться?',
    login: 'Войти?',
    forget: 'Забыли пароль?',
    tokenTitle: 'введите токен для входа',
    placeholder: 'от 8 до 16 символов',
    owner: 'Как владелец бизнеса',
    latin: 'Только латинские буквы',
    upper: 'Хотя бы 1 большая буква',
    lower: 'Хотя бы 1 маленькая буква',
    digit: 'Хотя бы 1 цифра',
    employee: 'Как сотрудник',
    emailTitle: 'Почта',
    passTitle: 'Пароль',
  },
  en: {
    format: '* Enter data in the required format',
    email:
      '* Email adress must contain symbol @ and end with the domain name after dot',
    required: '* Required field',
    min: '* Minimum 8 symbols',
    max: '* Maximum 16 symbols',
    pass: '* The password must contain upper and lowercase Latin letters and numbers',
    token: 'Token must be without restricted symbols',
    enter: 'Login',
    registration: 'Registration',
    register: 'Register?',
    login: 'Login?',
    forget: 'Forgot password?',
    tokenTitle: 'enter the token to log in',
    placeholder: 'from 8 to 16 symbols',
    owner: 'As business owner',
    latin: 'Latin letters only',
    upper: 'At least 1 uppercase letter',
    lower: 'At least 1 lowercase letter',
    digit: 'At least 1 digit',
    employee: 'As employee',
    emailTitle: 'Email',
    passTitle: 'Password',
  },
};

export const RegisterForm = ({ loginForm = false }) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [role, setRole] = useState(['owner']);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { lang = '' } = useSelector(state => state.auth);

  const selectedLang = lang ? lang : getUserLang();

  const schema = loginForm
    ? yup.object().shape({
        email: yup
          .string()
          .email(content[selectedLang].format)
          .matches(emailPattern, content[selectedLang].email)
          .required(content[selectedLang].required),
        password: yup.string().required(content[selectedLang].required),
      })
    : yup.object().shape({
        email: yup
          .string()
          .email(content[selectedLang].format)
          .matches(emailPattern, content[selectedLang].email)
          .required(content[selectedLang].required),
        password: yup
          .string()
          .min(8, content[selectedLang].min)
          .max(16, content[selectedLang].max)
          .matches(passwordPattern, content[selectedLang].pass)
          .required(content[selectedLang].required),
      });

  const tokenSchema = yup.object().shape({
    token: yup
      .string()
      .matches(inputPattern, content[selectedLang].token)
      .required(content[selectedLang].required),
  });

  const initialValue = {
    email: '',
    password: '',
    token: '',
  };

  const selectLangHandler = lang => {
    dispatch(setLang(lang));
  };

  const registerHandler = async newUser => {
    const { meta } = await dispatch(register(newUser));

    if (meta.requestStatus === 'fulfilled') {
      dispatch(setEmail({ email: newUser.email }));
      navigate('/register/success');
      window.scrollTo(0, 0);
    }
  };

  const submitHandler = async (values, { resetForm }) => {
    const { email, password, token = '' } = values;

    if (token) {
      dispatch(authenticate({ token }));
    } else {
      const newUser = {
        email: email.toLowerCase(),
        password,
        lang: selectedLang,
      };
      loginForm ? dispatch(login(newUser)) : registerHandler(newUser);
    }

    resetForm();
    window.scrollTo(0, 0);
  };

  const options = [
    { text: content[selectedLang].owner, value: 'owner' },
    { text: content[selectedLang].employee, value: 'employee' },
  ];

  return (
    <div className={css.container}>
      <Formik
        initialValues={initialValue}
        validationSchema={role[0] === 'owner' ? schema : tokenSchema}
        onSubmit={submitHandler}
      >
        {({ errors, touched }) => (
          <Form className={css.wrapper} autoComplete="off">
            {!loginForm && (
              <LangSelect
                onSelect={selectLangHandler}
                initialValue={selectedLang}
              />
            )}
            <h2 className={css.header}>
              {loginForm
                ? content[selectedLang].enter
                : content[selectedLang].registration}
            </h2>
            {loginForm && (
              <Select options={options} onSelect={value => setRole(value)} />
            )}
            {role[0] === 'owner' ? (
              <>
                <Link
                  className={css.link}
                  to={loginForm ? '/register' : '/login'}
                >
                  {loginForm
                    ? content[selectedLang].register
                    : content[selectedLang].login}
                </Link>
                <label className="name">
                  <span className="title">
                    {content[selectedLang].emailTitle}
                  </span>
                  <Field
                    className="field"
                    type="text"
                    name="email"
                    autoComplete="off"
                  />
                  {errors.email && touched.email ? (
                    <div className={css.error}>{errors.email}</div>
                  ) : null}
                </label>
                <label className="name">
                  <span className="title">
                    {content[selectedLang].passTitle}
                  </span>
                  <Field
                    className="field"
                    type={isPasswordShown ? 'text' : 'password'}
                    name="password"
                    autoComplete="off"
                    placeholder={
                      loginForm ? '' : content[selectedLang].placeholder
                    }
                  />
                  <button
                    className={
                      isPasswordShown
                        ? `${css.passBtn}`
                        : `${css.passBtn} ${css.shown}`
                    }
                    onClick={() => setIsPasswordShown(state => !state)}
                    type="button"
                    title={isPasswordShown ? 'hide password' : 'show password'}
                  >
                    {isPasswordShown ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </button>
                  {errors.password && touched.password ? (
                    <div className={css.error}>{errors.password}</div>
                  ) : loginForm ? null : (
                    <div className={css.info}>
                      <span className={css.condition}>
                        <Ok /> {content[selectedLang].latin}
                      </span>
                      <span className={css.condition}>
                        <Ok /> {content[selectedLang].upper}
                      </span>
                      <span className={css.condition}>
                        <Ok /> {content[selectedLang].lower}
                      </span>
                      <span className={css.condition}>
                        <Ok /> {content[selectedLang].digit}
                      </span>
                    </div>
                  )}
                </label>{' '}
              </>
            ) : (
              <label className="name" style={{ marginTop: '20px' }}>
                <span className="title">
                  {content[selectedLang].tokenTitle}
                </span>
                <Field
                  className="field"
                  type="text"
                  name="token"
                  autoComplete="off"
                />
                {errors.token && touched.token && (
                  <div className={css.error}>{errors.token}</div>
                )}
              </label>
            )}
            {loginForm && role[0] === 'owner' && (
              <Link className={css.passLink} to="/restore">
                {content[selectedLang].forget}
              </Link>
            )}
            <button type="submit" className="btn">
              {loginForm
                ? content[selectedLang].login
                : content[selectedLang].register}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
