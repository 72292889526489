import { api } from 'api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getCustomer = createAsyncThunk(
  'customers/getCustomer',
  async (credentials, thunkAPI) => {
    try {
      const {
        code = '',
        quantity = '',
        spotId = '',
        cookie,
        fingerprint = {},
      } = credentials;
      const { data } = await api.post(
        `/actions/${code}/${spotId}/${quantity}`,
        {
          cookie,
          fingerprint,
        }
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const sendResponse = createAsyncThunk(
  'customers/sendResponse',
  async (credentials, thunkAPI) => {
    try {
      const { actionId, customerName, rating, response, spot, time } =
        credentials;
      const { data } = await api.post(`/actions/${actionId}/response`, {
        customerName,
        rating,
        response,
        spot,
        time,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);
