import { useState } from 'react';
import { ReactComponent as Review } from 'icons/review.svg';
import { useSelector } from 'react-redux';
import { ShowRating } from 'components/ShowRating/ShowRating';
import { ReactComponent as Spot } from 'icons/loc.svg';
import { ReactComponent as Customer } from 'icons/customer.svg';
import { isoDateFormatter } from 'helpers/isoDateFormatter';
import css from './Responses.module.scss';

const content = {
  ru: {
    btn: 'отзывы клиентов',
  },
  uk: { btn: 'відгуки клієнтів' },
  en: { btn: 'clients reviews' },
};

// const reviews = [
//   {
//     rating: 3,
//     customer: '***jhhruyeb',
//     text: 'Очень хорошая шаурма. Советую добавіть красний соус для остроти вкуса',
//     spot: 'Чулка',
//   },
//   { rating: 5, customer: '***jh757eb', text: 'Шаурма огонь', spot: 'Крошня' },
// ];

export const Responses = ({ reviews }) => {
  const { lang = '' } = useSelector(state => state.auth);

  const [isShown, setIsShown] = useState(false);

  return (
    <div className={css.wrapper}>
      <button
        className={css.btn}
        type="button"
        onClick={() => setIsShown(!isShown)}
      >
        <Review /> {`${content[lang].btn} (${reviews?.length})`}
      </button>
      {reviews?.length > 0 &&
        reviews?.map(({ spot, customerName, rating, response, time }) => (
          <ul className={isShown ? `${css.list} ${css.shown}` : `${css.list}`}>
            <li className={css.item}>
              <div className={css.container}>
                <span className={css.spot}>
                  <Spot /> {spot}
                </span>
                <span className={css.customer}>
                  <Customer /> {customerName}
                </span>
              </div>
              <ShowRating rating={rating} />
              <p className={css.text}>{response}</p>
              <span className={css.time}>{isoDateFormatter(lang, time)}</span>
            </li>
          </ul>
        ))}
    </div>
  );
};
