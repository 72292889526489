import { useState, useEffect } from 'react';
import { ReactComponent as Arrow } from 'icons/arrowdown.svg';
import Ukraine from 'icons/ukraine.jpg';
import Russia from '../../icons/russia.jpg';
import Britain from '../../icons/britain.jpg';
import css from './LangSelect.module.scss';

const data = [
  { icon: Ukraine, value: 'uk' },
  { icon: Russia, value: 'ru' },
  { icon: Britain, value: 'en' },
];

export const LangSelect = ({ onSelect, initialValue = '' }) => {
  const [lang, setLang] = useState(initialValue);
  const [modalShown, setModalShown] = useState(false);

  useEffect(() => {
    if (initialValue) setLang(initialValue);
  }, [initialValue]);

  const selectHandler = value => {
    setModalShown(false);
    setLang(value);
    onSelect(value);
  };

  const getIcon = value => {
    const option = data.find(item => item.value === value);
    if (option) return option.icon;
    return Britain;
  };

  return (
    <div className={css.wrapper}>
      <button
        className={css.btn}
        type="button"
        onClick={() => setModalShown(state => !state)}
        title="Select a language"
      >
        <img src={getIcon(lang)} alt={lang} />
        <Arrow />
      </button>
      <ul className={modalShown ? `${css.list} ${css.shown}` : `${css.list}`}>
        {data.map(({ icon, value }, index) => (
          <li className={css.item} key={index}>
            <button
              type="button"
              className={css.option}
              onClick={() => selectHandler(value)}
            >
              <img src={icon} alt={value} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};
