import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { checkRestoreKey } from '../redux/slices/auth/operations';

const CheckRestore = () => {
  const { restoreKey = '' } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (restoreKey) {
        const { meta } = await dispatch(checkRestoreKey({ restoreKey }));

        if (meta.requestStatus === 'fulfilled') {
          navigate('/users/restore/resetPassword');
        }
      }
    };

    fetchData();
  }, [restoreKey, dispatch, navigate]);

  return null;
};

export default CheckRestore;
