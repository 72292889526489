import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Error from 'icons/error.png';
import css from './PaymentFail.module.scss';

const content = {
  ru: {
    error: 'Во время оплаты произошла ошибка!',
    errorTwo: 'Пожалуйста, попробуйте снова.',
    link: 'Перейти к оплате',
  },
  uk: {
    error: 'Під час оплати сталася помилка!',
    errorTwo: 'Будь ласка, спробуйте ще раз.',
    link: 'Перейти до оплати',
  },
  en: {
    error: 'An error occurred during payment!',
    errorTwo: 'Please try again.',
    link: 'Follow to payment',
  },
};

const PaymentFail = () => {
  const { lang } = useSelector(state => state.auth);

  return (
    <div className={css.container}>
      <div className={css.wrapper}>
        <img src={Error} alt="Error" className={css.img} />
      </div>
      <p className={css.error}>
        {content[lang].error}
        <br />
        {content[lang].errorTwo}
      </p>
      <Link to="/payment" className="btn">
        {content[lang].link}
      </Link>
    </div>
  );
};

export default PaymentFail;
