import { useEffect } from 'react';
import { ActionsList } from 'components/ActionsList/ActionsList';
import { ActionForm } from 'components/ActionForm/ActionForm';
import { ReactComponent as Arrow } from 'icons/arrowback.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAll } from '../redux/slices/actions/operations';
import { setAuthHeader } from '../api.js';

const Actions = () => {
  const { token } = useSelector(state => state.auth);
  const { actions } = useSelector(state => state.actions);

  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      setAuthHeader(token);
      dispatch(getAll());
    }
  }, [dispatch, token]);

  return (
    <>
      <Link to="/profile" className="link" title="back">
        <Arrow />
      </Link>
      <ActionsList list={actions} />
      <ActionForm />
    </>
  );
};

export default Actions;
