import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Promotion } from 'icons/promotion.svg';
import { ReactComponent as Spot } from 'icons/spot.svg';
import { ReactComponent as Employees } from 'icons/employees.svg';
import { ReactComponent as Payment } from 'icons/wallet.svg';
import { ReactComponent as Arrow } from 'icons/arrowright.svg';
import { ReactComponent as Lamp } from 'icons/lamp.svg';
import { ReactComponent as Hide } from 'icons/hide.svg';
import { setIsWarningShown } from '../../redux/slices/auth/slice';
import css from './MenuList.module.scss';

const content = {
  ru: {
    actions: 'Акции',
    spots: 'Торговые точки',
    employees: 'Сотрудники',
    payment: 'Оплата за сервис',
    stepOne:
      '1. Если вы планируете работать с сотрудниками, вам необходимо вначале добавить сотрудников.',
    step: 'Если вы планируете работать один, добавьте себя как сотрудника.',
    stepFirst:
      'В таком случае, чтобы получить доступ к Q-коду акции, вам потребуется войти в систему как сотрудник.',
    stepTwo:
      '2. Затем создать торговые точки и закрепить за каждой из точек соответсвующих сотрудников.',
    stepThree:
      '3. После этого создать акцию и указать торговые точки, которые будут принимать участие в этой акции.',
    close: 'скрыть',
  },
  uk: {
    actions: 'Акції',
    spots: 'Торгові точки',
    employees: 'Співробітники',
    payment: 'Оплата за сервіс',
    stepOne:
      '1. Якщо ви плануєте працювати зі співробітниками, спочатку додайте їх у систему.',
    step: 'Якщо ви плануєте працювати самостійно, добавте себе як співробітника.',
    stepFirst:
      ' У такому випадку для доступу до Q-коду акції вам потрібно буде увійти в систему як співробітник.',
    stepTwo:
      '2. Потім створіть торгові точки та закріпіть за кожною з них відповідних співробітників.',
    stepThree:
      '3. Після цього створіть акцію та вкажіть торгові точки, які братимуть участь у цій акції.',
    close: 'приховати',
  },
  en: {
    actions: 'Promotions',
    spots: 'Trade spots',
    employees: 'Employees',
    payment: 'Service payment',
    stepOne:
      '1. If you plan to work with employees, you need to first add them to the system.',
    step: 'If you plan to work alone, add yourself as an employee.',
    stepFirst:
      'In this case, to access the Q-code of the promotion, you will need to log in as an employee.',
    stepTwo:
      '2. Then create points of sale and assign the corresponding employees to each of them.',
    stepThree:
      '3. After that, create a promotion and specify the points of sale that will participate in this promotion.',
    close: 'hide',
  },
};

export const MenuList = () => {
  const { lang } = useSelector(state => state.auth);
  const [isInfoShown, setIsInfoShown] = useState(true);

  const { actions } = useSelector(state => state.auth.user);
  const { isWarningShown } = useSelector(state => state.auth);

  const options = [
    { text: content[lang].actions, link: '/actions', icon: <Promotion /> },
    { text: content[lang].spots, link: '/spots', icon: <Spot /> },
    { text: content[lang].employees, link: '/employees', icon: <Employees /> },
    { text: content[lang].payment, link: '/payment', icon: <Payment /> },
  ];

  const dispatch = useDispatch();

  const closeInfoHandler = () => {
    setIsInfoShown(false);
    dispatch(setIsWarningShown(false));
  };

  return (
    <>
      {actions?.length === 0 && isWarningShown && (
        <div
          className={`${css.infoWrapper} ${isInfoShown ? '' : css.isHidden}`}
        >
          <p className={css.notify}>
            <Lamp />
            {content[lang].stepOne}
            <br />
            {content[lang].step} <br />
            {content[lang].stepFirst} <br />
            {content[lang].stepTwo}
            <br />
            {content[lang].stepThree}
          </p>
          <button type="button" className={css.btn} onClick={closeInfoHandler}>
            <Hide />
          </button>
        </div>
      )}
      <ul
        className={css.list}
        style={{ marginTop: isInfoShown ? '20px' : 'auto' }}
      >
        {options.map(({ text, link, icon = '' }) => (
          <li className={css.item} key={link}>
            <Link
              to={link}
              className={css.link}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              {icon}
              {text}
              <Arrow className={css.icon} />
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};
