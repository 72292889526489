import { createSlice } from '@reduxjs/toolkit';
import {
  getAll,
  newAction,
  getOneById,
  deleteAction,
  checkPromo,
  checkActionPromo,
  getEmployeeProfile,
  getStats,
  authenticate,
  showActionPromo,
  editAction,
} from './operations';

const initialState = {
  actions: [],
  stats: {},
  action: {},
  actionPromo: {},
  isLoading: false,
  employeeToken: null,
  message: '',
  status: '',
  spots: [],
  employeeProfile: {},
  employeeActions: [],
  isAuthenticated: false,
  freeActions: 5,
  error: false,
  isLoggedIn: true,
};

const actionsSlice = createSlice({
  name: 'actions',
  initialState: initialState,
  reducers: {
    setActionsMessage: (state, action) => {
      state.message = action.payload;
    },
    setActionsStatus: (state, action) => {
      state.status = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    logout: (state, action) => {
      state.employeeProfile = {};
      state.employeeActions = [];
      state.employeeToken = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getAll.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.isLoading = false;
        state.actions = action.payload.actions;
        state.freeActions = action.payload.freeActions;
      })
      .addCase(getAll.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
        if (action.payload === 'Unauthorized') {
          state.error = true;
          state.isLoggedIn = false;
        }
      })
      .addCase(authenticate.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(authenticate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.employeeProfile = action.payload.employeeProfile;
        state.employeeActions = action.payload.employeeActions;
        state.employeeToken = action.payload.token;
      })
      .addCase(authenticate.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(newAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(newAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.actions = action.payload.actions;
        state.freeActions = action.payload.freeActions;
        state.message = action.payload.message;
        state.status = 'success';
      })
      .addCase(newAction.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
        if (action.payload === 'Unauthorized') {
          state.error = true;
          state.isLoggedIn = false;
        }
      })
      .addCase(getOneById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getOneById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.action = action.payload.action;
      })
      .addCase(getOneById.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
        if (action.payload === 'Unauthorized') {
          state.error = true;
          state.isLoggedIn = false;
        }
      })
      .addCase(editAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(editAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.action = action.payload.action;
      })
      .addCase(editAction.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
        if (action.payload === 'Unauthorized') {
          state.error = true;
          state.isLoggedIn = false;
        }
      })
      .addCase(deleteAction.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.status = 'success';
      })
      .addCase(deleteAction.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
        if (action.payload === 'Unauthorized') {
          state.error = true;
          state.isLoggedIn = false;
        }
      })
      .addCase(checkPromo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(checkPromo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.actionPromo = action.payload.actionPromo;
      })
      .addCase(checkPromo.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
        if (action.payload === 'Unauthorized') {
          state.error = true;
          state.isLoggedIn = false;
        }
      })
      .addCase(showActionPromo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(showActionPromo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.actionPromo = action.payload.actionPromo;
      })
      .addCase(showActionPromo.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(checkActionPromo.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(checkActionPromo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.actionPromo = action.payload.actionPromo;
      })
      .addCase(checkActionPromo.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
        if (action.payload === 'Unauthorized') {
          state.error = true;
          state.isLoggedIn = false;
        }
      })
      .addCase(getEmployeeProfile.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getEmployeeProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.employeeProfile = action.payload.employeeProfile;
      })
      .addCase(getEmployeeProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';

        if (action.payload === 'Unauthorized') {
          state.error = true;
          state.isLoggedIn = false;
        }
      })
      .addCase(getStats.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getStats.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stats = action.payload.stats;
      })
      .addCase(getStats.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
        if (action.payload === 'Unauthorized') {
          state.error = true;
          state.isLoggedIn = false;
        }
      });
  },
});

export const { setActionsMessage, setActionsStatus, logout, setError } =
  actionsSlice.actions;
export const actionsReducer = actionsSlice.reducer;
