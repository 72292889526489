export const isoDateFormatter = (lang, isoDate) => {
  const date = new Date(isoDate);
  const now = new Date();

  const word = { ru: 'сейчас', uk: 'зараз', en: 'now' };

  const diffInMs = now - date;

  if (diffInMs < 60000) {
    return word[lang];
  }

  const hour = date.getHours();
  const minute = date.getMinutes();

  return `${hour}:${minute < 10 ? '0' + minute : minute}`;
};
