import { ReactComponent as Star } from 'icons/star.svg';
import css from './ShowRating.module.scss';

export const ShowRating = ({ rating }) => {
  let currentRating = rating;

  if (rating > 5) {
    currentRating = 5;
  }

  let stars = [];

  for (let i = 0; i < currentRating; i += 1) {
    stars.push(i);
  }

  return (
    <ul className={css.list}>
      {stars.map((star, index) => (
        <li className={css.item} key={index}>
          {<Star />}
        </li>
      ))}
    </ul>
  );
};
