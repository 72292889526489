import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { ReactComponent as Success } from 'icons/success.svg';
import { emailPattern } from 'helpers/patterns';
import { restorePassword } from '../../redux/slices/auth/operations';
import { getUserLang } from 'helpers/getUserLang';
import css from './RestorePassword.module.scss';

const content = {
  ru: {
    format: '* Введите данные в нужном формате',
    email: '* Адрес электронной почты должен содержать символ @',
    required: '* Обязательное поле',
    back: 'назад',
    showEmail: 'Укажите вашу электронную почту',
    letter: 'Мы отправим на вашу почту письмо для восстановления пароля!',
    btn: 'Отправить письмо',
    sent: 'Мы отправили письмо на вашу почту!',
    link: 'Перейдите по ссылке в письме для восстановления пароля.',
  },
  uk: {
    format: '* Введіть дані в потрібному форматі',
    email: '* Адреса електронної пошти повинна містити символ @',
    required: "* Обов'язкове поле",
    back: 'назад',
    showEmail: 'Вкажіть вашу електронну пошту',
    letter: 'Ми відправимо на вашу пошту лист для відновлення паролю!',
    btn: 'Відправити лист',
    sent: 'Ми відправили лист на вашу пошту!',
    link: 'Перейдіть за посиланням в листі для відновлення паролю.',
  },
  en: {
    format: '* Enter data in required format',
    email: '* Email adress must contain symbol @',
    required: '* Required field',
    back: 'back',
    showEmail: 'Specify your email adress',
    letter: 'We will send you an email to reset your password!',
    btn: 'Send email',
    sent: 'We have sent you email!',
    link: 'Follow the link in email to reset password.',
  },
};

export const RestorePassword = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const dispatch = useDispatch();

  const userLang = useSelector(state => state.auth.lang);

  const lang = userLang || getUserLang();

  const schema = yup.object().shape({
    email: yup
      .string()
      .email(content[lang].format)
      .matches(emailPattern, content[lang].email)
      .required(content[lang].required),
  });

  const initialValue = {
    email: '',
  };

  const submitHandler = async (values, { resetForm }) => {
    const { email } = values;
    const { meta } = await dispatch(restorePassword({ email }));

    if (meta.requestStatus === 'fulfilled') {
      setIsSubmitted(true);
      resetForm();
    }
  };

  return (
    <div className={css.container}>
      {!isSubmitted ? (
        <Formik
          initialValues={initialValue}
          validationSchema={schema}
          onSubmit={submitHandler}
        >
          {({ errors, touched }) => (
            <Form className={css.wrapper} autoComplete="off">
              <Link className={css.link} to="/login">
                {content[lang].back}
              </Link>
              <label className={css.name}>
                {content[lang].showEmail}
                <Field
                  className="field"
                  type="text"
                  name="email"
                  autoComplete="off"
                />
                {errors.email && touched.email ? (
                  <div className={css.error}>{errors.email}</div>
                ) : null}
              </label>
              <span className={css.info}>{content[lang].letter}</span>
              <button type="submit" className="btn">
                {content[lang].btn}
              </button>
            </Form>
          )}
        </Formik>
      ) : (
        <>
          <Link className={css.link} to="/login">
            {content[lang].btn}
          </Link>
          <Success />
          <span className={css.text}>
            {content[lang].sent} <br />
            {content[lang].link}
          </span>
        </>
      )}
    </div>
  );
};
