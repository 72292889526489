import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ReactComponent as Ok } from 'icons/ok.svg';
import { getUserLang } from 'helpers/getUserLang';
import { passwordPattern } from 'helpers/patterns';
import { resetPassword } from '../redux/slices/auth/operations';
import css from './ResetPassword.module.scss';

const content = {
  uk: {
    format: '* Введіть дані в потрібному форматі',
    email: '* Адреса електронної пошти має містити символ @',
    required: "* Обов'язкове поле",
    min: '* Мінімум 8 символів',
    max: '* Максимум 16 символів',
    pass: '* Пароль повинен складатись з велеких та малих латинських літер та цифр',
    token: 'Токен повинен бути без заборонених символів',
    enter: 'Вхід',
    registration: 'Регістрація',
    register: 'Зареєструватись',
    login: 'Увійти?',
    forget: 'Забули пароль?',
    tokenTitle: 'уведіть токен для входу',
    placeholder: 'від 8 до 16 символів',
    owner: 'як власник бізнесу',
    latin: 'Лише латинські літери',
    upper: 'Хоча б 1 велика літера',
    lower: 'Хоча б 1 маленька літера',
    digit: 'Хотя бы 1 цифра',
    new: 'Вигадайте новий пароль',
    save: 'Зберегти',
    back: 'назад',
  },
  ru: {
    format: '* Введите данные в нужном формате',
    email: '* Адрес электронной почты должен содержать символ @',
    required: '* Обязательное поле',
    min: '* Минимум 8 символов',
    max: '* Максимум 16 символов',
    pass: '* Пароль должен содержать большие и маленькие латинские буквы и цифры',
    token: 'Токен должен быть без запрещенных символов',
    enter: 'Вход',
    registration: 'Регистрация',
    register: 'Зарегистрироваться',
    login: 'Войти?',
    forget: 'Забыли пароль?',
    tokenTitle: 'введите токен для входа',
    placeholder: 'от 8 до 16 символов',
    owner: 'как владелец бизнеса',
    latin: 'Только латинские буквы',
    upper: 'Хотя бы 1 большая буква',
    lower: 'Хотя бы 1 маленькая буква',
    digit: 'Хотя бы 1 цифра',
    new: 'Придумайте новый пароль',
    save: 'Сохранить',
    back: 'назад',
  },
  en: {
    format: '* Enter data in the required format',
    email: '* Email adress must contain symbol @',
    required: '* Required field',
    min: '* Minimum 8 symbols',
    max: '* Maximum 16 symbols',
    pass: '* The password must contain upper and lowercase Latin letters and numbers',
    token: 'Token must be without restricted symbols',
    enter: 'Login',
    registration: 'Registration',
    register: 'Register',
    login: 'Login?',
    forget: 'Forgot password?',
    tokenTitle: 'enter the token to log in',
    placeholder: 'from 8 to 16 symbols',
    owner: 'as business owner',
    latin: 'Latin letters only',
    upper: 'At least 1 uppercase letter',
    lower: 'At least 1 lowercase letter',
    digit: 'At least 1 digit',
    new: 'Create new password',
    save: 'Save',
    back: 'back',
  },
  pl: {
    format: '* Wprowadź dane we właściwym formacie',
    email: 'Adres e-mail musi zawierać symbol @',
    required: '* Pole wymagane',
    min: '* Minimum 8 znaków',
    max: '* Maksymalnie 16 znaków',
    pass: '* Hasło musi zawierać duże i małe litery łacińskie oraz cyfry',
    token: 'Token musi być bez zabronionych znaków',
    enter: 'Logowanie',
    registration: 'Rejestracja',
    register: 'Zarejestruj się',
    login: 'Wejść?',
    forget: 'Zapomniałeś hasła?',
    tokenTitle: 'Wprowadź token, aby się zalogować',
    placeholder: 'Od 8 do 16 znaków',
    latin: 'Tylko litery łacińskie',
    upper: 'Przynajmniej 1 wielka litera',
    lower: 'Przynajmniej 1 mała litera',
    digit: 'Przynajmniej 1 cyfra',
  },
  tr: {
    format: '* Gerekli formatta verileri girin',
    email: '* E-posta adresi @ sembolünü içermelidir',
    required: '* Zorunlu alan',
    min: '* En az 8 karakter',
    max: '* En fazla 16 karakter',
    pass: '* Şifre büyük ve küçük Latin harfleri ve rakamları içermelidir',
    token: 'Token, yasaklı karakterler olmadan olmalıdır',
    enter: 'Giriş',
    registration: 'Kayıt',
    register: 'Kayıt Ol',
    login: 'Giriş?',
    forget: 'Şifrenizi mi unuttunuz?',
    tokenTitle: 'Giriş yapmak için belirteci girin',
    placeholder: '8 ila 16 karakter arasında',
    latin: 'Sadece Latin harfleri',
    upper: 'En az 1 büyük harf',
    lower: 'En az 1 küçük harf',
    digit: 'En az 1 rakam',
  },
  sr: {
    format: '* Унесите податке у потребном формату',
    email: 'Адреса е-поште мора садржавати симбол @',
    required: '* Обавезно поље',
    min: '* Најмање 8 знакова',
    max: '* Максимум 16 знакова',
    pass: '* Шифра мора да садржи велика и мала латинична слова и цифре',
    token: 'Токен мора бити без забрањених знакова',
    enter: 'Пријава',
    registration: 'Регистрација',
    register: 'Региструј се',
    login: 'Улаз?',
    forget: 'Заборављена лозинка?',
    tokenTitle: 'Унесите токен за пријаву',
    placeholder: 'Од 8 до 16 карактера',
    latin: 'Само латинична слова',
    upper: 'Барем 1 велико слово',
    lower: 'Барем 1 мала слово',
    digit: 'Барем 1 цифра',
  },
  bg: {
    format: '* Въведете данните в необходимия формат',
    email: 'Имейл адресът трябва да съдържа символа @',
    required: '* Задължително поле',
    min: '* Поне 8 знака',
    max: '* Максимум 16 символа',
    pass: '* Паролата трябва да съдържа големи и малки латински букви и цифри',
    token: '* Токенът трябва да е без забранени символи',
    enter: 'Вход',
    registration: 'Регистрация',
    register: 'Регистрирай се',
    login: 'Вход?',
    forget: 'Забравена парола?',
    tokenTitle: 'Въведете токен, за да влезете',
    placeholder: 'От 8 до 16 символа',
    latin: 'Само латински букви',
    upper: 'Поне 1 голяма буква',
    lower: 'Поне 1 малка буква',
    digit: 'Поне 1 цифра',
  },
  ro: {
    format: '* Intrați datele în formatul necesar',
    email: 'Adresa de email trebuie să conțină simbolul @',
    required: '* Câmp obligatoriu',
    min: '* Minim 8 caractere',
    max: '* Maximum 16 caractere',
    pass: '* Parola trebuie să conțină litere mari și mici și cifre',
    token: 'Tokenul trebuie să fie fără caractere interzise',
    enter: 'Autentificare',
    registration: 'Înregistrare',
    register: 'Înregistrare',
    login: 'Intrare?',
    forget: 'Ți-ai uitat parola?',
    tokenTitle: 'Introduceți token-ul pentru autentificare',
    placeholder: 'De la 8 la 16 caractere',
    latin: 'Numai litere latine',
    upper: 'Cel puțin 1 literă mare',
    lower: 'Cel puțin 1 literă mică',
    digit: 'Cel puțin 1 cifră',
  },
};

const ResetPassword = () => {
  const [selectedLang, setSelectedLang] = useState('en');

  const { email } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const schema = yup.object().shape({
    password: yup
      .string()
      .min(8, content[selectedLang].min)
      .max(16, content[selectedLang].max)
      .matches(passwordPattern, content[selectedLang].pass)
      .required(content[selectedLang].required),
  });

  const initialValue = {
    password: '',
  };

  const submitHandler = async (values, { resetForm }) => {
    const { password } = values;

    const { meta } = await dispatch(resetPassword({ email, password }));

    if (meta.requestStatus === 'fulfilled') {
      navigate('/login');
      resetForm();
    }
  };

  useEffect(() => {
    const lang = getUserLang();
    setSelectedLang(lang);
  }, [setSelectedLang]);

  return (
    <div className={css.container}>
      <Link className={css.link} to="/login">
        {content[selectedLang].back}
      </Link>
      <label className="name">
        <span className="title">email</span>
        <input
          className="field"
          type="text"
          name="email"
          autoComplete="off"
          value={email}
          readOnly
        />
      </label>
      <Formik
        initialValues={initialValue}
        validationSchema={schema}
        onSubmit={submitHandler}
      >
        {({ errors, touched }) => (
          <Form className={css.wrapper} autoComplete="off">
            <span className={css.title}>{content[selectedLang].new}</span>
            <label className={css.name}>
              <Field
                className="field"
                type="text"
                name="password"
                autoComplete="off"
                placeholder={content[selectedLang].placeholder}
              />
              {errors.password && touched.password ? (
                <div className={css.error}>{errors.password}</div>
              ) : null}
            </label>
            <div className={css.info}>
              <span className={css.condition}>
                <Ok /> {content[selectedLang].latin}
              </span>
              <span className={css.condition}>
                <Ok /> {content[selectedLang].upper}
              </span>
              <span className={css.condition}>
                <Ok /> {content[selectedLang].lower}
              </span>
              <span className={css.condition}>
                <Ok /> {content[selectedLang].digit}
              </span>
            </div>
            <button type="submit" className="btn">
              {content[selectedLang].save}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ResetPassword;
