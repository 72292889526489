import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ShowQr } from 'components/ShowQr/ShowQr';
import { Loader } from 'components/Loader/Loader';
import { showActionPromo } from '../redux/slices/actions/operations';
import css from './ShowPromo.module.scss';

const content = {
  ru: {
    every: 'Акция: каждый(-ая)',
    gift: 'в подарок!',
    show: 'Покажите этот промо-код, чтобы получить подарок',
    invalid: 'Ссылка недействительна',
  },
  uk: {
    every: 'Акція: кожен(-на)',
    gift: 'у подарунок!',
    show: 'Покажіть цей промо-код, щоб отримати подарунок',
    invalid: 'Посилання недійсне',
  },
  en: {
    every: 'Promotion: every',
    gift: 'for free!',
    show: 'Show this promo code to get your gift',
    invalid: 'Link is invalid',
  },
};

const ShowPromo = () => {
  const { id = '', promo = '' } = useParams();

  const dispatch = useDispatch();

  const { actionPromo } = useSelector(state => state.actions);

  useEffect(() => {
    if (id && promo) {
      dispatch(showActionPromo({ id, promo }));
    }
  }, [id, promo, dispatch]);

  return Object.values(actionPromo).length > 0 ? (
    actionPromo.valid ? (
      <div className={css.container}>
        <p className={css.header}>{`${content[actionPromo.lang].every} ${
          actionPromo?.quantity || ''
        } ${actionPromo?.product || ''} ${content[actionPromo.lang].gift}`}</p>
        <p className={css.title}>{content[actionPromo.lang].show}</p>
        <div className={css.wrapper}>
          <ShowQr promo={promo} actionId={id} lang={actionPromo.lang} />
        </div>
      </div>
    ) : (
      <p>{content[actionPromo.lang].invalid}</p>
    )
  ) : (
    <Loader />
  );
};

export default ShowPromo;
