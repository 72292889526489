import { api, setAuthHeader } from 'api';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAll = createAsyncThunk(
  'actions/getAll',
  async (credentials, thunkAPI) => {
    try {
      const { data } = await api.get('/actions');
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const newAction = createAsyncThunk(
  'actions/newAction',
  async (credentials, thunkAPI) => {
    try {
      const { data } = await api.post('/actions/new', credentials);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const editAction = createAsyncThunk(
  'actions/editAction',
  async (credentials, thunkAPI) => {
    try {
      const { _id, lang, product, quantity, spots, responsesAllowed } =
        credentials;
      const { data } = await api.post(`/actions/${_id}/edit`, {
        lang,
        product,
        quantity,
        spots,
        responsesAllowed,
      });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getOneById = createAsyncThunk(
  'actions/getOneById',
  async (credentials, thunkAPI) => {
    try {
      const { id, period, spot } = credentials;
      const { data } = await api.get(
        `/actions/${id}?date=${period}&spot=${spot}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteAction = createAsyncThunk(
  'actions/deleteAction',
  async (credentials, thunkAPI) => {
    try {
      const { id } = credentials;
      const { data } = await api.delete(`/actions/${id}`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const checkPromo = createAsyncThunk(
  'actions/checkPromo',
  async (credentials, thunkAPI) => {
    try {
      const { data } = await api.post(`/actions/promo`, credentials);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const showActionPromo = createAsyncThunk(
  'actions/showActionPromo',
  async (credentials, thunkAPI) => {
    try {
      const { id, promo } = credentials;
      const { data } = await api.get(`/actions/${id}/show/${promo}`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const checkActionPromo = createAsyncThunk(
  'actions/checkActionPromo',
  async (credentials, thunkAPI) => {
    try {
      const { id, promo } = credentials;
      const { data } = await api.get(`/actions/${id}/promo/${promo}`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getEmployeeProfile = createAsyncThunk(
  'actions/getEmployeeProfile',
  async (credentials, thunkAPI) => {
    try {
      const { id } = credentials;
      const { data } = await api.get(`/actions/${id}/employee`);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const authenticate = createAsyncThunk(
  'actions/authenticate',
  async (credentials, thunkAPI) => {
    try {
      const { data } = await api.post('/employees/authenticate', credentials);
      if (data.token) {
        setAuthHeader(data.token);
      }
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export const getStats = createAsyncThunk(
  'actions/getStats',
  async (credentials, thunkAPI) => {
    try {
      const { id, period, spot } = credentials;
      const { data } = await api.get(
        `/actions/${id}/stats?period=${period}&spot=${spot}`
      );
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);
