export const getPointsWord = (lang, amount) => {
  if (!lang || !amount) return;

  const words = {
    ru: {
      first: 'балл',
      second: 'балла',
      third: 'баллов',
    },
    uk: { first: 'бал', second: 'бали', third: 'балів' },
    en: { first: 'point', second: 'points', third: 'points' },
  };

  let count;

  if (Number(amount) === 1) {
    count = 'first';
  } else if (Number(amount) > 1 && Number(amount) < 5) {
    count = 'second';
  } else {
    count = 'third';
  }

  return words[lang][count];
};
