import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ImLocation2 } from 'react-icons/im';
import { ReactComponent as Customers } from 'icons/customers.svg';
import { toTitleCase } from 'helpers/toTitleCase';
import { getActionWord } from 'helpers/getActionWord';
import css from './ActionsList.module.scss';

const content = {
  ru: {
    title: 'Ваши акции:',
    every: 'Каждый(-ая)',
    gift: ' в подарок',
    absent: 'У вас пока нет акций.',
    available: 'Доступно',
  },
  uk: {
    title: 'Ваші акції:',
    every: 'Кожен(-а)',
    gift: 'в подарунок',
    absent: 'У вас поки не має акцій.',
    available: 'Доступно',
  },
  en: {
    title: 'Your promotions:',
    every: 'Every',
    gift: 'free',
    absent: 'I have no promotions yet.',
    available: 'You have',
  },
};

export const ActionsList = ({ list }) => {
  const { lang } = useSelector(state => state.auth);
  const { freeActions } = useSelector(state => state.actions);

  return list.length > 0 ? (
    <>
      <p className={css.header}>{content[lang].title}</p>
      <span className={css.notify}>{`${
        content[lang].available
      } ${freeActions} ${getActionWord(lang, freeActions)}`}</span>
      <ul className={css.list}>
        {list.map(({ _id, product, quantity, spots, customers }) => (
          <li className={css.item} key={_id}>
            <Link to={`/actions/${_id}`} className={css.link}>
              <p className={css.title}>{`${
                content[lang].every
              } ${quantity} ${product.toLowerCase()} ${content[lang].gift}`}</p>
              <span className={css.customers}>
                <Customers />
                {customers?.length || customers}
              </span>
              {spots.length > 0 && (
                <div className={css.location}>
                  <ImLocation2 />
                  <ul className={css.adress}>
                    {spots?.map((name, index) => (
                      <li key={index}>{toTitleCase(name)}</li>
                    ))}
                  </ul>
                </div>
              )}
            </Link>
          </li>
        ))}
      </ul>
    </>
  ) : (
    <>
      <p className={css.header}>{content[lang].title}</p>
      <p className={css.absent}>{content[lang].absent}</p>
    </>
  );
};
