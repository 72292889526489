import { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { ReactComponent as Add } from 'icons/add.svg';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { newEmployee } from '../../redux/slices/auth/operations';
import { inputPattern } from 'helpers/patterns';
import { toTitleCase } from 'helpers/toTitleCase';
import css from './EmployeeForm.module.scss';

const content = {
  ru: {
    name: '* Имя не должно содержать запрещенные символы',
    required: '* Обязательное поле',
    add: 'добавить сотрудника',
    employeeName: 'Имя сотрудника',
    btn: 'Добавить',
    exist: 'У вас уже есть сотрудник с именем',
  },
  uk: {
    name: "* Ім'я не повинно містити заборонені символи",
    required: "* Обов'язкове поле",
    add: 'додати співробітника',
    employeeName: "Ім'я співробітника",
    btn: 'Додати',
    exist: "У вас вже є співробітник з ім'ям",
  },
  en: {
    name: '* Name must not contain restricted characters',
    required: '* Required field',
    add: 'add employee',
    employeeName: 'Employee name',
    btn: 'Add',
    exist: 'You already have employee with name',
  },
};

export const EmployeeForm = () => {
  const [isFormShown, setIsFormShown] = useState(false);
  const [value, setValue] = useLocalStorage('employeeForm', '');
  const [existingName, setExistingName] = useState('');

  const { employeesList } = useSelector(state => state.auth.user);
  const { lang } = useSelector(state => state.auth);

  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(inputPattern, content[lang].name)
      .required(content[lang].required),
  });

  const formRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const openHandler = () => {
    setIsFormShown(state => !state);
    if (window.scrollY > 0) {
      setTimeout(() => {
        formRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 500);
    }
  };

  const handleFormChange = e => {
    const { value } = e.target;

    setValue(value);
    setExistingName(false);
  };

  const submitHandler = async (values, { resetForm }) => {
    const { name } = values;

    const trimmedName = name.trim().toLowerCase();
    const lowercaseEmployeesList = employeesList?.map(employee =>
      employee.toLowerCase()
    );
    const upperCasedName = toTitleCase(trimmedName);

    if (lowercaseEmployeesList.includes(trimmedName)) {
      setExistingName(upperCasedName);
      return;
    }
    const { meta } = await dispatch(newEmployee({ name: upperCasedName }));

    if (meta.requestStatus === 'fulfilled') {
      localStorage.removeItem('employeeForm');
      resetForm();
      setValue('');
      navigate('/employees');
    }
    window.scrollTo(0, 0);
  };

  return (
    <div className={css.wrapper}>
      <button className={css.btn} type="button" onClick={openHandler}>
        <Add /> {content[lang].add}
      </button>
      <div className={isFormShown ? `${css.form} ${css.shown}` : `${css.form}`}>
        <Formik
          initialValues={{ name: value }}
          validationSchema={schema}
          onSubmit={submitHandler}
        >
          {({ errors, touched, handleChange }) => (
            <Form autoComplete="off" ref={formRef}>
              <label className="name">
                <span className="title">{content[lang].employeeName}</span>
                <Field
                  className="field"
                  name="name"
                  type="text"
                  onChange={e => {
                    handleChange(e);
                    handleFormChange(e);
                  }}
                />
                {errors.name && touched.name && (
                  <div className={css.error}>{errors.name}</div>
                )}
                {existingName && (
                  <div
                    className={css.error}
                  >{`${content[lang].exist} ${existingName}`}</div>
                )}
              </label>
              <button type="submit" className="btn">
                {content[lang].btn}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
