import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { ReactComponent as Add } from 'icons/add.svg';
import { RiEdit2Fill } from 'react-icons/ri';
import { Select } from 'components/Select/Select';
import { Toggler } from 'components/Toggler/Toggler';
import { MdEdit } from 'react-icons/md';
import { newAction, editAction } from '../../redux/slices/actions/operations';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { inputPattern } from '../../helpers/patterns';
import { getArray } from '../../helpers/getArray';
import { setMessage, setStatus } from '../../redux/slices/auth/slice';
import css from './ActionForm.module.scss';

const content = {
  ru: {
    name: '* Название не должно содержать запрещенные символы',
    required: '* Обязательное поле',
    min: '* Минимум 2',
    max: '* Максимум 100',
    edit: 'редактировать акцию',
    create: 'создать акцию',
    product: 'Товар',
    productName: 'Название товара',
    placeholder: 'Например: шаурма',
    free: 'Товар будет бесплатным каждый n-ый раз',
    amountPlaceholder: 'укажите n-нное число',
    spots: 'Торговые точки для этой акции',
    absent: 'У вас пока нет торговых точек',
    add: 'добавить торговую точку',
    warning: ' Необходимо выбрать хотя бы одну торговую точку для этой акции',
    btn: 'Создать',
    save: 'Сохранить',
    allow: 'Разрешить оставлять отзывы о вашем бизнесе',
    absentActions: 'У вас больше нет доступных акций',
  },
  uk: {
    name: '* Назва не повинна містити заборонені символи',
    required: "* Обов'язкове поле",
    min: '* Мінімум 2',
    max: '* Максимум 100',
    edit: 'редагувати акцію',
    create: 'створити акцію',
    product: 'Товар',
    productName: 'Назва товару',
    placeholder: 'Наприклад: шаурма',
    free: 'Товар буде безкоштовним кожен n-ий раз',
    amountPlaceholder: 'вкажіть n-нне число',
    spots: 'Торгові точки для цієї акції',
    absent: 'У вас ще немає торгових точок',
    add: 'додати торгову точку',
    warning: 'Потрібно вибрати хоча б одну торгову точку для цієї акції',
    btn: 'Створити',
    save: 'Зберегти',
    allow: 'Дозволити залишати відгуки про ваш бізнес',
    absentActions: 'У вас більше немає доступних акцій',
  },
  en: {
    name: '* Name should not contain restricted characters',
    required: '* Required field',
    min: '* Minimum 2',
    max: '* Maximum 100',
    edit: 'edit promotion',
    create: 'create promotion',
    product: 'Product',
    productName: 'Product name',
    placeholder: 'For example: shawarma',
    free: 'Product will be free every n-th time',
    amountPlaceholder: 'specify n-th number',
    spots: 'Trade spots for this promotion',
    absent: "You don't have any trade spots yet",
    add: 'add trade spot',
    warning: 'At least one trade spot must be selected for this promotion',
    btn: 'Create',
    save: 'Save',
    allow: 'Allow to leave responses about your business',
    absentActions: 'You have no more available promotions',
  },
};

export const ActionForm = ({
  initialValues = {},
  width,
  isEditable = false,
}) => {
  const {
    product = '',
    quantity = '',
    spots = [],
    _id = '',
    responsesAllowed = false,
  } = initialValues;
  const [editValue, setEditValue] = useState('');
  const [isFormShown, setIsFormShown] = useState(false);
  const [formValues, setFormValues] = useLocalStorage(
    'actionForm',
    {
      product: product || '',
      quantity: quantity || '',
    },
    true
  );
  const [selectedSpots, setSelectedSpots] = useLocalStorage(
    'selectedSpots',
    []
  );
  const [isSpotAbsent, setIsSpotAbsent] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isAllowed, setIsAllowed] = useState(responsesAllowed || false);

  const spotsList = useSelector(state => state.auth.user.spots);
  const { lang } = useSelector(state => state.auth);
  const { freeActions } = useSelector(state => state.auth.user);

  const schema = yup.object().shape({
    product: yup
      .string()
      .matches(inputPattern, content[lang].name)
      .required(content[lang].required),
    quantity: yup
      .number()
      .min(2, content[lang].min)
      .max(100, content[lang].max)
      .required(content[lang].required),
  });

  const nameRef = useRef(null);
  const quantityRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formRef = useRef(null);

  const openHandler = () => {
    setIsFormShown(state => !state);
    if (window.scrollY > 0) {
      setTimeout(() => {
        formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500);
    }
  };

  const submitHandler = async (values, { resetForm }) => {
    if (selectedSpots.length === 0) {
      setIsSpotAbsent(true);
      return;
    }

    if (freeActions < 1) {
      dispatch(setMessage(content[lang].absentActions));
      dispatch(setStatus('error'));
      return;
    }
    const { product, quantity } = values;

    const action = {
      lang,
      product,
      quantity,
      spots: selectedSpots,
      responsesAllowed: isAllowed,
    };

    const editData = {
      _id,
      lang,
      product,
      quantity,
      spots: selectedSpots,
      responsesAllowed: isAllowed,
    };

    const data = isEditable ? editData : action;

    let response;

    if (isEditable) {
      response = await dispatch(editAction(data));
    } else {
      response = await dispatch(newAction(data));
    }

    if (response.meta.requestStatus === 'fulfilled') {
      localStorage.removeItem('actionForm');
      localStorage.removeItem('selectedSpots');
      setFormValues({ product: '', quantity: '' });
      resetForm();
      setSelectedSpots([]);
      setIsFormShown(false);
      setIsSubmitted(true);
      setIsSubmitted(false);
      navigate('/actions');
      window.scrollTo(0, 0);
    }
  };

  const allowedHandler = value => {
    setIsAllowed(value);
  };

  useEffect(() => {
    if (isSpotAbsent) {
      setTimeout(() => setIsSpotAbsent(false), 3500);
    }
  }, [isSpotAbsent]);

  useEffect(() => {
    if (Object.values(initialValues).length > 0) {
      setFormValues({
        product: initialValues.product,
        quantity: initialValues.quantity,
      });
      setSelectedSpots(initialValues.spots);
      setIsAllowed(initialValues.responsesAllowed);
    }
  }, [initialValues, setIsAllowed, setSelectedSpots, setFormValues]);

  useEffect(() => {
    if (!editValue) return;

    if (editValue === 'product' && nameRef.current) {
      nameRef.current.focus();
    } else if (editValue === 'quantity' && quantityRef.current) {
      quantityRef.current.focus();
    }
  }, [editValue]);

  return (
    <div className={css.wrapper} style={{ width: width }}>
      <button className={css.btn} type="button" onClick={openHandler}>
        {isEditable ? (
          <>
            <RiEdit2Fill />
            {content[lang].edit}
          </>
        ) : (
          <>
            <Add /> {content[lang].create}
          </>
        )}
      </button>
      <div className={isFormShown ? `${css.form} ${css.shown}` : `${css.form}`}>
        <Formik
          initialValues={formValues}
          validationSchema={schema}
          onSubmit={submitHandler}
        >
          {({ errors, touched, handleChange }) => {
            const handleFormChange = e => {
              const { name, value } = e.target;

              handleChange(e);

              setFormValues(prevValues => ({ ...prevValues, [name]: value }));
            };

            return (
              <Form autoComplete="off" ref={formRef}>
                <label className="name">
                  <span className="title">
                    {isEditable
                      ? `${content[lang].product}`
                      : `${content[lang].productName}`}
                  </span>
                  {isEditable && (
                    <button
                      type="button"
                      onClick={() => setEditValue('product')}
                      className={css.edit}
                      title="edit product"
                    >
                      <MdEdit />
                    </button>
                  )}
                  <Field
                    innerRef={nameRef}
                    className="field"
                    placeholder={content[lang].placeholder}
                    name="product"
                    type="text"
                    onChange={handleFormChange}
                    readOnly={isEditable && editValue !== 'product'}
                  />
                  {errors.product && touched.product && (
                    <div className={css.error}>{errors.product}</div>
                  )}
                </label>
                <label className="name" style={{ marginBottom: '10px' }}>
                  <span className="title">{content[lang].free}</span>
                  {isEditable && (
                    <button
                      type="button"
                      onClick={() => setEditValue('quantity')}
                      className={css.edit}
                      title="edit quantity"
                    >
                      <MdEdit />
                    </button>
                  )}
                  <Field
                    innerRef={quantityRef}
                    type="number"
                    name="quantity"
                    className="field"
                    placeholder={content[lang].amountPlaceholder}
                    onChange={handleFormChange}
                    readOnly={isEditable && editValue !== 'quantity'}
                  />
                  {errors.quantity && touched.quantity && (
                    <div className={css.error}>{errors.quantity}</div>
                  )}
                </label>
                <Toggler
                  text={content[lang].allow}
                  onToggleChange={allowedHandler}
                  initialValue={isAllowed}
                />
                <label className={css.select}>
                  {content[lang].spots}
                  {spotsList?.length > 0 ? (
                    <Select
                      options={getArray(spotsList)}
                      onSelect={values => setSelectedSpots(values)}
                      multy={true}
                      onRefresh={isSubmitted}
                      initialValues={spots}
                    />
                  ) : (
                    <>
                      <p className={css.absent}>{content[lang].absent}</p>
                      <Link
                        className={css.link}
                        to="/spots"
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                      >
                        <Add />
                        {content[lang].add}
                      </Link>
                    </>
                  )}
                </label>
                <p
                  className={
                    isSpotAbsent
                      ? `${css.warning} ${css.shown}`
                      : `${css.warning}`
                  }
                >
                  {content[lang].warning}
                </p>
                <button type="submit" className="btn">
                  {isEditable ? content[lang].save : content[lang].btn}
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
