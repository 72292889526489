import { useEffect, useState } from 'react';
import css from './ShowQr.module.scss';
import { QRCodeSVG } from 'qrcode.react';
import { Counter } from 'components/Counter/Counter';

const content = {
  ru: {
    promo: 'Промо-код акции',
    code: 'QR-код акции',
    amount: 'Количество баллов к зачислению',
  },
  uk: {
    promo: 'Промо-код акції',
    code: 'QR-код акції',
    amount: 'Кількість балів до нарахування',
  },
  en: {
    promo: 'Promotion code',
    code: 'Promotion QR code',
    amount: 'Points amount to credit',
  },
};

export const ShowQr = ({
  code = '',
  quantity,
  spotId = '',
  actionId = '',
  promo,
  refresh = false,
  lang,
}) => {
  const [counter, setCounter] = useState(1);
  const [link, setLink] = useState('');

  const baseURL = 'https://loyal2yourbusiness.com/customer/';
  const promoURL = `https://loyal2yourbusiness.com/actions/${actionId}/promo/${promo}`;

  useEffect(() => {
    const link = promo ? promoURL : `${baseURL}${code}/${spotId}/${counter}`;
    setLink(link);
  }, [counter, promo, code, promoURL, spotId, setLink]);

  useEffect(() => {
    if (refresh) {
      setCounter(1);
    }
  }, [refresh]);

  return (
    <div className={css.wrapper}>
      <p className={css.head}>
        {promo ? `${content[lang].promo}` : `${content[lang].code}`}
      </p>
      {!promo && (
        <>
          <p className={css.title}>{content[lang].amount}</p>
          <Counter
            onSelect={setCounter}
            max={quantity - 1}
            onRefresh={refresh}
          />
        </>
      )}
      <div className={css.codeWrapper}>
        <QRCodeSVG value={link} size={'100%'} />
      </div>
    </div>
  );
};
