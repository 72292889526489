import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const RestrictedRoute = ({ component: Component }) => {
  const { isLoggedIn } = useSelector(state => state.auth);
  const { isAuthenticated } = useSelector(state => state.actions);
  const redirectTo = isAuthenticated ? '/employees/profile' : '/';

  return isLoggedIn || isAuthenticated ? (
    <Navigate to={redirectTo} />
  ) : (
    Component
  );
};
