export const common = {
  uk: {
    success: 'Успіх',
  },
  ru: { success: 'Успех' },
  en: { success: 'Success' },
  tr: { success: 'Başarı' },
  pl: { success: 'Powodzenie' },
  ro: { success: 'Succes' },
  bg: { success: 'Успех' },
  sr: { success: 'Успех' },
};
