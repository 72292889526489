export const content = {
  ru: {
    title: 'Вы владелец бизнеса?',
    secondTitle: 'Думаете как привлечь постоянных клиентов?',
    start: 'Внедрите акцию для постоянных клиентов',
    middle: 'По типу каждый N-нный товар в подарок',
    example: 'Например: каждый 10 кофе в подарок!!!',
    use: 'Используйте для этого данный сервис',
    how: 'Как работает наш сервис?',
    stepOne:
      'Вы, как владелец бизнеса, регистрируетесь и создаете акцию в нашем сервисе. Указываете акционный товар, а также какие из ваших торговых точек будут участвовать в акции и при необходимости даёте доступ вашим сотрудникам к этой акции.',
    stepTwo:
      'Каждый раз, когда ваши клиенты покупают акционный товар, вы или ваши сотрудники показываете QR-код акции вашим клиентам.',
    acc: 'Ваши клиенты просто сканируют QR-код и накапливают баллы акции, а также могут оставлять отзывы об акции,',
    load: 'без необходимости загружать какие-либо приложения или проходить регистрацию в нашем сервисе.',
    can: 'После достижения необходимого количества баллов, они могут получить акционный товар в качестве подарка, предьявив QR-код в телефоне.',
    advantageTitle: 'Преимущества нашего сервиса?',
    advantageOne:
      'При классической реализации подобной акции, вы, как собственник бизнеса, изготавливливаете карточки, раздаете их вашим клиентам. Каждый раз при покупке акционного товара каким-либо образом отмечаете покупку на этих карточках. Либо вы просити каждого вашего клиента зарегистрироватся в системе вашей программы учёта продаж. Оба этих варианта имеют ряд недостатков:',
    pointOne: '- клиенты часто теряют карточки',
    pointTwo:
      '- если вы хотите внедрить несколько акций для вашего бизнеса одновременно, вам необходимо для каждой акции раздавать отдельную карточку',
    pointThree:
      '- необходимость регистрации отталкивает клиентов от участия в акции',
    noticeOne:
      'В отличие от вышеописанного подхода, наш сервис максимально удобен для ваших клиентов. Всё, что от них требуется, это отсканировать QR-код акции при каждой покупке акционного товара. Телефон клиента является его идентификатором в системе нашего сервиса.',
    noticeTwo:
      'Клиент сможет видеть свой баланс баллов и историю накопления баллов в своем телефоне без какой-либо регистрации.',
    stats:
      'Вы, как собственник бизнеса, сможете видеть статистику акции по каждой торговой точке и за период времени:',
    amount: '- количество участий',
    participants: '- количество участников акции',
    gifts: '- количество выданых подарков',
    reviews: '- отзывы ваших клиентов',
    advantageTwo:
      'Наш сервис исключает какие-либо манипуляции со стороны клиентов и сотрудников вашего бизнеса. QR-код акции может быть использован только один раз. При успешном сканировании QR-кода вашим клиентом, автоматически генерируется новый QR-код для всех торговых точек. Для получения подарка, ваш клиент предьявляет соответсвующий QR-код вам или вашему сотруднику на своем телефоне. После его сканирования, он становится невалидным.',
    promo:
      'Вы также сможете передавать вашим клиентам промо-код акции, чтобы они могли получить акционный товар в качестве подарка вне зависимости от баланса баллов клиента. Промо-код часто используют в качестве компенсации клиенту за некачественное обслуживание для удержания его лояльности к вашему бизнесу.',
    business: 'Для каких видов бизнеса подходит наш сервис?',
    coffee: 'Кофейни',
    doner: 'Шаурмичные',
    pizza: 'Пиццерии',
    smoke: 'Кальянные',
    wash: 'Автомойки',
    barber: 'Парихмахерские',
    register: 'Зарегистрироваться',
    others:
      'Любой другой вид бизнеса, который продает товар или услугу по фиксированной цене',
    check:
      'Убедитесь лично в простоте, удобстве и надежности нашего сервиса. Первые 30 дней использования абсолютно бесплатно! Вы можете создать до 5 акций и добавить любое количество торговых точек, чтобы протестировать наш сервис.',
  },
  uk: {
    title: 'Ви власник бізнесу?',
    secondTitle: 'Бажаєте залучити постійних клієнтів?',
    start: 'Впровадьте акцію для постійних клієнтів',
    middle: 'Типу кожен N-ий товар у подарунок',
    example: 'Наприклад: кожна 10 кава у подарунок!!!',
    use: 'Використайте для цього наш сервіс',
    how: 'Як працює наш сервіс?',
    stepOne:
      'Ви, як власник бізнесу, реєструєтесь та створюєте акцію у нашому сервісі. Вказуєте акційний товар, а також які з ваших торгових точок будуть брати участь у акції та, у разі необхідності, надаєте доступ вашим співробітникам до цієї акції.',
    stepTwo:
      'Кожного разу, коли ваші клієнти купують акційний товар, ви або ваші співробітники показують QR-код акції вашим клієнтам.',
    acc: 'Ваші клієнти просто сканують QR-код та накопичують бали акції, а також залишати відгуки про акцію,',
    load: 'без необхідності завантаження будь-яких додатків чи проходження реєстрації в нашому сервісі.',
    can: "Після досягнення необхідної кількості балів, вони можуть отримати акційний товар у вигляді подарунка, пред'явивши QR-код на телефоні.",
    advantageTitle: 'Переваги нашого сервісу?',
    advantageOne:
      'При класичній реалізації подібної акції ви, як власник бізнесу, виготовляєте картки, роздаєте їх вашим клієнтам. Кожного разу під час покупки акційного товару яким-небудь чином відмічаєте покупку на цих картках. Або ви просите кожного вашого клієнта зареєструватися в системі вашої програми обліку продажів. Обидва цих варіанта мають ряд недоліків:',
    pointOne: '- клієнти часто втрачають картки',
    pointTwo:
      '- якщо ви хочете впровадити кілька акцій для вашого бізнесу одночасно, вам необхідно для кожної акції роздавати окрему картку',
    pointThree:
      '- необхідність реєстрації відштовхує клієнтів від участі в акції',
    noticeOne:
      'На відміну від вищезгаданого підходу, наш сервіс максимально зручний для ваших клієнтів. Все, що від них потрібно, просто зісканувати QR-код акції під час кожної покупки акційного товару. Телефон клієнта є його ідентифікатором у системі нашого сервісу.',
    noticeTwo:
      'Клієнт зможе бачити свій баланс балів та історію накопичення балів у своєму телефоні без будь-якої реєстрації.',
    stats:
      'Ви, як власник бізнесу, зможете бачити статистику акції по кожній торговій точці та за період часу:',
    amount: '- кількість участей',
    participants: '- кількість учасників акції',
    gifts: '- кількість видач подарунків',
    reviews: '- відгуки ваших клієнтів',
    advantageTwo:
      "Наш сервіс виключає будь-які маніпуляції з боку клієнтів та співробітників вашого бізнесу. QR-код акції може бути використаний лише один раз. Після успішного сканування QR-кода вашим клієнтом автоматично генерується новий QR-код для всіх торгових точок. Для отримання подарунка ваш клієнт пред'являє відповідний QR-код вам або вашому співробітнику на своєму телефоні. Після його сканування він стає недійсним.",
    promo:
      'Ви також зможете передавати вашим клієнтам промо-код акції, щоб вони могли отримати акційний товар у вигляді подарунка незалежно від балансу балів клієнта. Промо-код часто використовується як компенсація клієнту за неналежне обслуговування для утримання його лояльності до вашого бізнесу.',
    business: 'Для яких видів бізнесу підходить наш сервіс?',
    coffee: "Кав'ярні",
    doner: 'Шаурмичні',
    pizza: 'Піцерії',
    smoke: 'Кальянні',
    wash: 'Автомийки',
    barber: 'Перукарні',
    register: 'Зареєструватись',
    others:
      'Будь-який інший вид бізнесу, який продає товар або послугу за фіксованою ціною',
    check:
      'Переконайтеся особисто у простоті, зручності та надійності нашого сервісу. Перші 30 днів використання безкоштовно! Ви можете створити до 5 акцій і додати будь-яку кількість торгових точок, щоб протестувати наш сервіс.',
  },
  en: {
    title: 'Are You a Business Owner?',
    secondTitle: 'Thinking of Attracting Repeat Customers?',
    start: 'Implement a promotion for repeat customers',
    middle: 'Such as every N-th item free',
    example: 'For example: every 10 coffees free!!!',
    use: 'Use this service for that purpose',
    how: 'How Does Our Service Work?',
    stepOne:
      'As a business owner, you register and create a promotion in our service. You specify promotion product and which of your locations will participate in the promotion and, if necessary, grant access to this promotion to your employees.',
    stepTwo:
      'Every time your customers purchase a promotional item, you or your employees show the promotion QR code to your customers.',
    acc: 'Your customers simply scan the QR code and accumulate promotion points, as well as leave reviews about the action,',
    load: 'without the need to download any apps or register in our service.',
    can: 'After reaching the required number of points, they can receive the promotional item as a gift by presenting the QR code on their phone.',
    advantageTitle: 'Advantages of Our Service?',
    advantageOne:
      'With the traditional implementation of such a promotion, as a business owner, you make cards, distribute them to your customers. Every time a promotional item is purchased, you somehow mark the purchase on these cards. Or you ask each of your customers to register in your sales tracking system. Both of these options have drawbacks:',
    pointOne: '- customers often lose cards',
    pointTwo:
      '- if you want to implement multiple promotions for your business simultaneously, you need to distribute a separate card for each promotion',
    pointThree:
      '- the registration requirement discourages customers from participating in the promotion',
    noticeOne:
      "Unlike the above approach, our service is as convenient as possible for your customers. All they need to do is scan the promotion QR code with each purchase of a promotional item. The customer's phone serves as their identifier in our service system.",
    noticeTwo:
      'The customer can view their points balance and points accumulation history on their phone without any registration.',
    stats:
      'As a business owner, you will be able to see promotion statistics for each location and over time:',
    amount: '- number of participations',
    participants: '- number of promotion participants',
    gifts: '- number of gifts issued',
    reviews: '- your clients reviews',
    advantageTwo:
      'Our service excludes any manipulation by your customers and employees. The promotion QR code can only be used once. After a successful scan of the QR code by your customer, a new QR code is automatically generated for all locations. To receive a gift, your customer presents the corresponding QR code to you or your employee on their phone. After scanning it, it becomes invalid.',
    promo:
      "You can also give your customers a promotion code so they can receive the promotional item as a gift regardless of the customer's points balance. The promotion code is often used as compensation to the customer for poor service to maintain their loyalty to your business.",
    business: 'What Types of Businesses Does Our Service Suit?',
    coffee: 'Coffee Shops',
    doner: 'Kebab Shops',
    pizza: 'Pizzerias',
    smoke: 'Hookah Lounges',
    wash: 'Car Washes',
    barber: 'Barber Shops',
    register: 'Register',
    others:
      'Any other type of business that sells goods or services at a fixed price',
    check:
      'Experience firsthand the simplicity, convenience, and reliability of our service. The first 30 days of usage are absolutely free! You can create up to 5 promotions and add any number of trading points to test our service.',
  },
};
