export const getActionWord = (lang, amount) => {
  if (!lang || !amount) return;

  const content = {
    ru: { one: 'акция', two: 'акции', three: 'акций' },
    uk: { one: 'акція', two: 'акції', three: 'акцій' },
    en: { one: 'promotion', two: 'promotions', three: 'promotions' },
  };

  let count;

  if (amount === 1) {
    count = 'one';
  } else if (amount > 1 && amount < 5) {
    count = 'two';
  } else {
    count = 'three';
  }

  return content[lang][count];
};
