import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { MdLocationPin } from 'react-icons/md';
import { MdDelete } from 'react-icons/md';
import { ReactComponent as Shop } from 'icons/shop.svg';
import { Select } from 'components/Select/Select';
import { Modal } from 'components/Modal/Modal';
import { getArray } from 'helpers/getArray';
import { toTitleCase } from 'helpers/toTitleCase';
import {
  changeEmployees,
  deleteSpot,
} from '../../redux/slices/auth/operations';
import css from './SpotsList.module.scss';

const content = {
  ru: {
    confirm: 'Вы уверены, что хотите удалить эту торговую точку?',
    title: 'Ваши торговые точки:',
    actions: 'Акции:',
    every: 'Каждый(-ая)',
    gift: 'в подарок!!!',
    absent: 'Пока не участвует ни в одной из акций.',
    employees: 'Сотрудники:',
    no: 'У вас пока нет торговых точек',
    available: 'Доступно',
    spots: 'торговых точок',
  },
  uk: {
    confirm: 'Ви впевнені, що хочете видалити цю торгову точку?',
    title: 'Ваші торгові точки:',
    actions: 'Акції:',
    every: 'Кожен(-на)',
    gift: 'у подарунок!!!',
    absent: 'Поки не бере участі ні в одній з акцій.',
    employees: 'Співробітники:',
    no: 'У вас поки що немає торгових точок',
    available: 'Доступно',
    spots: 'торгових точок',
  },
  en: {
    confirm: 'Are you sure you want to delete this trade spot?',
    title: 'Your trade spots:',
    actions: 'Promotions:',
    every: 'Every',
    gift: 'for free!!!',
    absent: 'Not participating in any promotions yet.',
    employees: 'Employees:',
    no: "You don't have any trade spots yet",
    available: 'You have',
    spots: 'trading spots',
  },
};

export const SpotsList = ({ list }) => {
  const [spotId, setSpotId] = useState('');

  const dispatch = useDispatch();

  const { employeesList } = useSelector(state => state.auth.user);
  const { lang, freeSpots } = useSelector(state => state.auth);

  const deleteSelectedSpotHandler = id => {
    setSpotId(id);
  };

  const deleteSpotHandler = () => {
    dispatch(deleteSpot({ id: spotId }));
    setSpotId('');
  };

  const employeeSelectHandler = (values, id) => {
    dispatch(changeEmployees({ employees: values, id }));
  };

  return list?.length > 0 ? (
    <>
      <Modal
        onOpen={spotId}
        text={content[lang].confirm}
        onClose={() => setSpotId('')}
        onClick={deleteSpotHandler}
      />
      <p className={css.title}>{content[lang].title}</p>
      <span
        className={css.notify}
      >{`${content[lang].available} ${freeSpots} ${content[lang].spots}`}</span>
      <ul className={css.list}>
        {list.map(({ name, adress, actions, employees, _id }) => (
          <li className={css.item} key={_id}>
            <p className={css.name}>
              <Shop /> {toTitleCase(name)}
            </p>
            <p className={css.adress}>
              <MdLocationPin /> {toTitleCase(adress)}
            </p>
            <p className={css.action}>
              {content[lang].actions}
              <br />
              {actions.length > 0 ? (
                actions.map(({ product, quantity }, index) => (
                  <>
                    <span className={css.condition} key={index}>
                      {index + 1}. {content[lang].every}{' '}
                      <span className={css.highlight}>{quantity}</span>{' '}
                      <span className={css.highlight}>{product}</span>{' '}
                      {content[lang].gift}
                    </span>
                    <br />
                  </>
                ))
              ) : (
                <span className={css.condition} key={_id}>
                  {content[lang].absent}
                </span>
              )}
            </p>
            <span className={css.employee}>{content[lang].employees}</span>
            {(employeesList?.length > 0 || employees.length > 0) && (
              <Select
                options={getArray([...employeesList, ...employees])}
                onSelect={values => employeeSelectHandler(values, _id)}
                multy={true}
                initialValues={employees}
              />
            )}
            <button
              className={css.btn}
              type="button"
              onClick={() => deleteSelectedSpotHandler(_id)}
              title="delete"
            >
              <MdDelete />
            </button>
          </li>
        ))}
      </ul>
    </>
  ) : (
    <p className={css.absent}>{content[lang].no}</p>
  );
};
