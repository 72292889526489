import { useState } from 'react';
import { ReactComponent as Star } from 'icons/star.svg';
import css from './Rating.module.scss';

const buttons = ['', '', '', '', ''];

export const Rating = ({ onChange, initialValue }) => {
  const [currentRating, setCurrentRating] = useState(initialValue);

  const changeHandler = value => {
    const selectedValue = value + 1;
    setCurrentRating(selectedValue);
    onChange(selectedValue);
  };

  return (
    <div className={css.wrapper}>
      {buttons.map((btn, index) => (
        <button
          className={
            index + 1 <= currentRating
              ? `${css.btn} ${css.selected}`
              : `${css.btn}`
          }
          type="button"
          onClick={() => changeHandler(index)}
          key={index}
        >
          <Star />
        </button>
      ))}
    </div>
  );
};
