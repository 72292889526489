import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUserLang } from 'helpers/getUserLang';
import css from './Modal.module.scss';

const content = {
  ru: {
    yes: 'да',
    no: 'нет',
  },
  uk: { yes: 'так', no: 'ні' },
  en: { yes: 'yes', no: 'no' },
};

export const Modal = ({ text, onClick, onOpen = false, onClose }) => {
  const [isShown, setIsShown] = useState(onOpen);

  const { lang = '' } = useSelector(state => state.auth);

  const selectedLang = lang ? lang : getUserLang();

  useEffect(() => {
    setIsShown(onOpen);
  }, [onOpen]);

  const closeHandler = () => {
    onClose(false);
  };

  return (
    <div
      className={
        !isShown ? `${css.container} ${css.shown}` : `${css.container}`
      }
    >
      <div
        className={isShown ? `${css.wrapper} ${css.shown}` : `${css.wrapper}`}
      >
        <p className={css.text}>{text}</p>
        <div className={css.btnWrapper}>
          <button type="button" onClick={onClick} className="btn">
            {content[selectedLang].yes}
          </button>
          <button type="button" onClick={closeHandler} className={css.btn}>
            {content[selectedLang].no}
          </button>
        </div>
      </div>
    </div>
  );
};
