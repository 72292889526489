import { ReactComponent as Notfound } from 'icons/notfound.svg';
import css from './NotFound.module.scss';

const NotFound = () => {
  return (
    <div className={css.container}>
      <Notfound className={css.icon} />
      <p className={css.text}>Страница не найдена</p>
    </div>
  );
};

export default NotFound;
