import { useState, useEffect } from 'react';
import css from './Toggler.module.scss';

export const Toggler = ({ text, onToggleChange, initialValue = false }) => {
  const [isOn, setIsOn] = useState(initialValue);

  const handleToggle = () => {
    const newState = !isOn;
    setIsOn(newState);
    onToggleChange(newState);
  };

  useEffect(() => {
    setIsOn(initialValue);
  }, [initialValue]);

  return (
    <div className={css.wrapper}>
      <span className={css.text}>{text}</span>
      <label className={isOn ? `${css.title} ${css.checked}` : `${css.title}`}>
        <input
          className={css.field}
          type="checkbox"
          onChange={handleToggle}
          checked={isOn}
        />
        <span
          className={isOn ? `${css.btn} ${css.checked}` : `${css.btn}`}
        ></span>
      </label>
    </div>
  );
};
