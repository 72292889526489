import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import {
  getCustomer,
  sendResponse,
} from '../redux/slices/customers/operations';
import { GiftQR } from 'components/GiftQR/GiftQR';
import { ReactComponent as Balance } from 'icons/balance.svg';
import { ReactComponent as Home } from 'icons/home.svg';
import { ReactComponent as Time } from 'icons/time.svg';
import { ReactComponent as Logo } from 'icons/logo.svg';
import { ReactComponent as Lamp } from 'icons/lamp.svg';
import { FiShoppingCart } from 'react-icons/fi';
import { FaGift } from 'react-icons/fa6';
import { Rating } from 'components/Rating/Rating';
import gift from '../icons/gift.png';
import errorImage from '../icons/error.png';
import { showDate } from '../helpers/showTime';
import { getPointsWord } from 'helpers/getPointsWord';
import { getUserLang } from 'helpers/getUserLang';
import { common } from '../content/common';
import { setMessage, setStatus } from '../redux/slices/auth/slice';
import socket from '../socket';
import css from './Customer.module.scss';

const content = {
  uk: {
    success: 'Успіх',
    valid: 'QR-код дійсний',
    every: 'Кожен(-на)',
    receive: 'Отримайте свій подарунок!',
    warningOne: 'Цей код може бути використаний лише один раз!!!',
    warningTwo:
      'Ви не зможете накопичувати бали далі, поки не отримаєте подарунок!!!',
    warningThree:
      "Ви зможете отримати подарунок наступного разу, пред'явивши цей QR-код! Щоб отримати доступ до цього QR-коду наступного разу, потрібно буде просканувати QR-код акції у торговій точці.",
    actionWord: 'Акція:',
    gift: 'Вам подарунок 1',
    giftWord: 'в подарунок',
    count: 'Вам зараховано',
    balance: 'Ваш баланс:',
    history: 'Історія накопичення:',
    invalid: 'Посилання недійсне.',
    check: 'Перевірте QR-код і спробуйте ще раз.',
    accept: 'Прийняти файли cookie',
    reject: 'Відхилити',
    notifyOne: 'Онлайн сервіс обліку балів промо-акцій',
    notifyTwo: 'для малого та середнього бізнесу',
    modal:
      'Ми використовуємо файли cookie, щоб забезпечити оптимальну роботу нашого веб-сайту та покращити ваш досвід користування. Ми не зберігаємо жодної особистої інформації. Продовжуючи використовувати наш сайт, ви погоджуєтеся з нашою політикою відносно файлів cookie.',
    rating: 'Оцініть якість обслуговування',
    response: 'Залиште відгук',
    send: 'Відправити',
    skip: 'Пропустити',
    sent: 'Відгук відправлено',
  },
  ru: {
    every: 'Каждый(-ая)',
    success: 'Успех',
    valid: 'QR-код действительный',
    receive: 'Получите свой подарок!',
    warningOne: 'Этот код может быть использован только один раз!!!',
    warningTwo:
      'Вы не сможете накапливать баллы дальше, пока не получите подарок!!!',
    warningThree:
      'Вы сможете получить подарок в следующий раз, предьявив этот QR-код! Чтобы получить доступ к этому QR-коду в следующий раз, необходимо будет сосканировать QR-код акции на торговой точке.',
    actionWord: 'Акция:',
    gift: 'Вам подарок 1',
    giftWord: 'в подарок',
    count: 'Вам зачислено',
    balance: 'Ваш баланс:',
    history: 'История накопления:',
    invalid: 'Cсылка недействительна.',
    check: 'Проверьте QR-код и попробуйте ещё раз.',
    accept: 'Принять файлы cookie',
    reject: 'Отклонить',
    notifyOne: 'Онлайн сервис учёта баллов промо-акций',
    notifyTwo: 'для малого и среднего бизнеса',
    modal:
      'Мы используем файлы cookie, чтобы обеспечить оптимальную работу нашего веб-сайта и улучшить ваш опыт использования. Мы не храним никакую персональную информацию. Продолжая использовать наш сайт, вы соглашаетесь с нашей политикой в отношении файлов cookie.',
    rating: 'Оцените качество обслуживания',
    response: 'Оставьте отзыв',
    send: 'Отправить',
    skip: 'Пропустить',
    sent: 'Отзыв отправлен',
  },
  en: {
    every: 'Every',
    success: 'Success',
    valid: 'QR-code is valid',
    receive: 'Receive your gift!',
    warningOne: 'This code can be used only once!!!',
    warningTwo:
      "You won't be able to accumulate point until you receive the gift!!!",
    warningThree:
      'You will be able to receive a gift next time by presenting this QR code! To access this QR code next time, you will need to scan the promotion QR code at a point of sale.',
    actionWord: 'Promotion:',
    gift: 'You have a gift 1',
    giftWord: 'free',
    count: 'You are credited',
    balance: 'Your balance:',
    history: 'History of accumulation:',
    invalid: 'The link is invalid.',
    check: 'Check the QR code and try again.',
    accept: 'Accept cookie files',
    reject: 'Reject',
    notifyOne: 'Online service for tracking promo campaign points',
    notifyTwo: 'for small and medium businesses',
    modal:
      'We use cookies to ensure the optimal performance of our website and to enhance your user experience. We do not store any personal information. By continuing to use our site, you agree to our cookie policy.',
    rating: 'Rate the quality of service',
    response: 'Leave a review',
    send: 'Send',
    skip: 'Skip',
    sent: 'Response is sent',
  },
};

const Customer = () => {
  const { code = '', quantity = '', spotId = '' } = useParams();

  const [giftShown, setGiftShown] = useState(false);
  const [giftReceived, setGiftReceived] = useState(false);

  const { customer, success } = useSelector(state => state.customers);

  const [isModalShown, setIsModalShown] = useState(false);
  const [isWindowShown, setIsWindowShown] = useState(false);
  const [rating, setRating] = useState(0);
  const [initial, setInitial] = useState(true);
  const [cookieAccepted, setCookieAccepted] = useState(
    Boolean(Cookies.get('customerCookie'))
  );

  const dispatch = useDispatch();
  const lang = getUserLang();

  useEffect(() => {
    if (customer.gift && customer.actionId && customer.customerName) {
      socket.emit('gift', {
        actionId: customer.actionId,
        name: customer.customerName,
      });
    }

    socket.on('gift_checked', async () => {
      setGiftReceived(true);
    });
  }, [customer.gift, customer.actionId, customer.customerName]);

  useEffect(() => {
    if (!cookieAccepted) {
      setIsModalShown(true);
    } else {
      setInitial(false);
    }
  }, [cookieAccepted, setIsModalShown]);

  useEffect(() => {
    if (customer.existingGift) {
      setGiftShown(true);
      return;
    } else if (customer.gift) {
      setTimeout(() => setGiftShown(true), 4000);
    }
  }, [customer.gift, customer.existingGift, setGiftShown]);

  useEffect(() => {
    if (code && quantity && spotId && !initial) {
      const { userAgent = '', platform: userPlatform } = navigator;
      const innerWidth = window.innerWidth || null;
      const innerHeight = window.innerHeight || null;
      const cookie = Cookies.get('customerCookie');
      dispatch(
        getCustomer({
          code,
          quantity,
          spotId,
          cookie: cookie ? cookie : '',
          fingerprint: {
            userAgent,
            platform: userPlatform,
            innerWidth,
            innerHeight,
          },
        })
      );
    }
  }, [code, quantity, spotId, initial, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      if (customer.responsesAllowed && !customer.existingGift) {
        setIsWindowShown(true);
      }
    }, 4000);
  }, [customer.responsesAllowed, customer.existingGift]);

  const acceptCookieHandler = () => {
    const string = uuidv4().slice(0, 8);
    Cookies.set('customerCookie', string, { expires: 30 });
    setCookieAccepted(true);
    setIsModalShown(false);
  };

  const submitHandler = async e => {
    e.preventDefault();

    const form = e.target;
    const response = form.elements.response.value.trim();
    console.log('response', response);
    if (response) {
      const responseObj = {
        rating,
        response,
        spot: spotId,
        actionId: customer?.actionId,
        customerName: customer?.customerName,
        time: new Date().toISOString(),
      };

      console.log(responseObj);

      const { meta } = await dispatch(sendResponse(responseObj));

      if (meta?.requestStatus === 'fulfilled') {
        form.reset();
        setIsWindowShown(false);
        setTimeout(() => {
          dispatch(setStatus('success'));
          dispatch(setMessage(content[lang].sent));
        }, 750);
      }
    }
  };

  return (
    <div className={css.container}>
      {customer && Object.values(customer)?.length > 0 ? (
        <>
          <Link to="/" className={css.notifyWrapper}>
            <p className={css.notify}>
              <Logo /> loyal2yourbusiness.com
            </p>
          </Link>
          <div>
            {success ? (
              <>
                <h3 className={css.actionTitle}>
                  {content[customer.lang].actionWord}
                </h3>
                <p className={css.actionCondition}>
                  {`${content[customer.lang].every} ${
                    customer.quantity
                  } ${customer.product.toLowerCase()} ${
                    content[customer.lang].giftWord
                  }!!!`}
                </p>
                {giftShown ? (
                  <div className={css.solo}>
                    {(giftShown || customer.existingGift) &&
                      (!giftReceived ? (
                        <div className={css.giftWrapper}>
                          <div className={css.imageWrapper}>
                            <img src={gift} className={css.img} alt="gift" />
                          </div>
                          <h3 className={css.header}>{`${
                            content[customer.lang].gift
                          } ${customer.product}!!!`}</h3>
                          {customer?.promo && customer?.actionId && (
                            <>
                              <GiftQR
                                promo={customer.promo}
                                actionId={customer.actionId}
                                lang={customer.lang}
                              />
                              <div className={css.warningWrapper}>
                                <Lamp />
                                <p className={css.warning}>
                                  {content[customer.lang].warningOne}
                                </p>
                                <p className={css.warning}>
                                  {content[customer.lang].warningTwo}
                                </p>
                                <p className={css.warning}>
                                  {content[customer.lang].warningThree}
                                </p>
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className={css.animate}>
                          <svg
                            width="100"
                            height="100"
                            viewBox="9 14 30 10"
                            fill="none"
                            stroke="#64C404"
                            stroke-width="4"
                            stroke-dasharray="100"
                            strokeLinecap="round"
                          >
                            <path
                              id="check"
                              d="M 12,22 L 22,31 L 36,13"
                              stroke-dashoffset="0"
                            >
                              <animate
                                attributeName="stroke-dashoffset"
                                from="100"
                                to="0"
                                dur="3.5s"
                              ></animate>
                            </path>
                          </svg>
                          <span className={css.shadow}></span>
                          <p className={css.successTitle}>
                            {content[customer.lang].success}
                          </p>
                          <p className={css.info}>
                            {content[customer.lang].valid}
                          </p>
                          <p className={css.codeTitle}>
                            {content[customer.lang].receive}
                          </p>
                        </div>
                      ))}
                  </div>
                ) : (
                  <div className={css.animate}>
                    <svg
                      width="100"
                      height="100"
                      viewBox="9 14 30 10"
                      fill="none"
                      stroke="#02e6ca"
                      strokeWidth="4"
                      strokeDasharray="100"
                      strokeLinecap="round"
                    >
                      <path
                        id="check"
                        d="M 12,22 L 22,31 L 36,13"
                        strokeDashoffset="0"
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          from="100"
                          to="0"
                          dur="3.5s"
                        ></animate>
                      </path>
                    </svg>
                    <span className={css.shadow}></span>
                    <span className={css.success}>
                      {common[customer.lang].success}
                    </span>
                  </div>
                )}
                {customer.amount > 0 &&
                  !customer.existingGift &&
                  !giftShown && (
                    <p className={css.count}>{`${
                      content[customer.lang].count
                    } ${customer.amount} ${getPointsWord(
                      customer.lang,
                      customer.amount
                    )}`}</p>
                  )}
                <div className={css.wrapper}>
                  <p className={css.balance}>
                    <Balance /> {`${content[customer.lang].balance}`}
                    <span className={css.balanceCount}>{`${customer.balance}/${
                      customer.quantity - 1
                    }`}</span>
                  </p>
                  {customer.history.length > 0 && (
                    <>
                      <p className={css.title}>
                        {content[customer.lang].history}
                      </p>
                      <div className={css.listWrapper}>
                        <ul className={css.icons}>
                          <li className={css.icon}>
                            <FiShoppingCart />
                          </li>
                          <li
                            className={css.icon}
                            style={{ marginRight: 'auto', marginLeft: 'auto' }}
                          >
                            <Time />
                          </li>
                          <li className={css.icon}>
                            <Home />
                          </li>
                        </ul>
                        <ul className={css.list}>
                          {customer?.history?.map(
                            ({ adress, amount, time }, index) => (
                              <li className={css.item} key={index}>
                                <span
                                  style={{
                                    textAlign: 'center',
                                    minWidth: '20px',
                                  }}
                                >
                                  {amount === 0 ? <FaGift /> : amount}
                                </span>
                                <span
                                  style={{
                                    width: '20%',
                                    marginLeft: 'auto',
                                    marginRight: '10%',
                                    textAlign: 'center',
                                  }}
                                >
                                  {showDate(customer?.lang, time)}
                                </span>
                                <span
                                  style={{
                                    width: '30%',
                                    textAlign: 'right',
                                    fontWeight: '900',
                                  }}
                                >
                                  {adress}
                                </span>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <div className={css.errorWrapper}>
                <img src={errorImage} alt="error" className={css.error} />
                <p className={css.errorText}>
                  {`${content[customer.lang].invalid}`} <br />
                  {`${content[customer.lang].check}`}
                </p>
              </div>
            )}
          </div>
        </>
      ) : null}
      <div
        className={
          isModalShown ? `${css.backdrop} ${css.shown}` : `${css.backdrop}`
        }
      >
        <div className={css.modal}>
          <p className={css.text}>{content[lang].modal}</p>
          <button
            type="button"
            className={css.btn}
            onClick={acceptCookieHandler}
          >
            {content[lang].accept}
          </button>
          <button
            type="button"
            className={css.reject}
            onClick={() => {
              setIsModalShown(false);
              setInitial(false);
            }}
          >
            {content[lang].reject}
          </button>
        </div>
      </div>
      <div
        className={
          isWindowShown ? `${css.window} ${css.shown}` : `${css.window}`
        }
      >
        <p className={css.windowTitle}>{content[lang].rating}</p>
        <Rating onChange={setRating} initialValue={rating} />
        <form className={css.form} onSubmit={submitHandler}>
          <label
            className={css.windowTitle}
            style={{ marginBottom: '20px', marginTop: '20px' }}
          >
            {content[lang].response}{' '}
          </label>
          <textarea
            className="field"
            name="response"
            type="text"
            style={{ marginBottom: '30px', resize: 'none' }}
          ></textarea>
          <button type="submit" className="btn">
            {content[lang].send}
          </button>
        </form>
        <button
          className={css.skip}
          type="button"
          onClick={() => setIsWindowShown(false)}
        >
          {content[lang].skip}
        </button>
      </div>
    </div>
  );
};

export default Customer;
