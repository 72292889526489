import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'components/Select/Select';
import { Loader } from 'components/Loader/Loader';
import { ShowQr } from 'components/ShowQr/ShowQr';
import { Modal } from 'components/Modal/Modal';
import { isoDateFormatter } from 'helpers/isoDateFormatter';
import { getEmployeeProfile } from '../redux/slices/actions/operations';
import { ReactComponent as Empty } from 'icons/empty.svg';
import { FaGift } from 'react-icons/fa6';
import { MdLocationPin } from 'react-icons/md';
import { ReactComponent as Exit } from 'icons/logout.svg';
import { ReactComponent as Info } from 'icons/info.svg';
import { ReactComponent as Time } from 'icons/time.svg';
import { ReactComponent as Man } from 'icons/man.svg';
import { FiShoppingCart } from 'react-icons/fi';
import { setAuthHeader } from 'api';
import { logout } from '../redux/slices/actions/slice';
import socket from '../socket';
import css from './EmployeeProfile.module.scss';

const content = {
  ru: {
    every: 'Каждый(-ая)',
    gift: 'в подарок!!!',
    actions: 'Доступные акции:',
    name: 'Имя сотрудника:',
    exist:
      'У данного клиента уже имеется подарок. Попросите его предъявить QR-код и отсканируйте его. Если код действителен, вручите подарок клиенту.',
    history: 'История акции за сегодня:',
    absent: 'Пока нет истории',
    user: 'пользователь',
    amount: 'количество',
    time: 'время',
    exit: 'выйти',
    confirm: 'Вы уверенны, что хотите выйти?',
  },
  uk: {
    every: 'Кожен(-на)',
    gift: 'у подарунок!!!',
    actions: 'Доступні акції:',
    name: "Ім'я співробітника:",
    exist:
      "У даного клієнта вже є подарунок. Попросіть його пред'явити QR-код і проскануйте його. Якщо код дійсний, вручіть подарунок клієнту.",
    history: 'Історія акції за сьогодні:',
    absent: 'Поки немає історії',
    user: 'користувач',
    amount: 'кількість',
    time: 'час',
    exit: 'вийти',
    confirm: 'Ви впевнені, що хочете вийти?',
  },
  en: {
    every: 'Every',
    gift: 'for free!!!',
    actions: 'Available promotions:',
    name: 'Employee name:',
    exist:
      'This customer already has a gift. Ask him to present the QR code and scan it. If the code is valid, give the gift to the customer.',
    history: 'Promotion history for today:',
    absent: 'No history yet',
    user: 'user',
    amount: 'amount',
    time: 'time',
    exit: 'logout',
    confirm: 'Are you sure that you want to logout?',
  },
};

const EmployeeProfile = () => {
  const [currentActionId, setCurrentActionId] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [gift, setGift] = useState(false);
  const [selectedLang, setSelectedLang] = useState('en');
  const [isModalShown, setIsModalShown] = useState(false);

  const { employeeProfile, employeeActions, employeeToken } = useSelector(
    state => state.actions
  );

  const { quantity, employee, code, history, adress, spotId, lang } =
    employeeProfile;

  const getActionArray = actions => {
    return actions.map(({ product, quantity, _id }) => ({
      text: `${
        content[selectedLang].every
      } ${quantity} ${product.toLowerCase()} ${content[selectedLang].gift}`,
      value: _id,
    }));
  };

  const dispatch = useDispatch();
  const initialMount = useRef(null);

  const actionSelectHandler = id => {
    setCurrentActionId(id);
    initialMount.current = true;
  };

  const handleLogout = () => {
    dispatch(logout());
    setIsModalShown(false);
  };

  useEffect(() => {
    if (initialMount.current && employeeToken) {
      setAuthHeader(employeeToken);
      dispatch(getEmployeeProfile({ id: currentActionId }));
    }
  }, [currentActionId, employeeToken, dispatch]);

  useEffect(() => {
    if (employeeActions.length > 0 && !currentActionId) {
      setCurrentActionId(employeeActions[0]._id);
      setSelectedLang(employeeActions[0].lang);
      initialMount.current = true;
    }
  }, [employeeActions, currentActionId]);

  useEffect(() => {
    if (currentActionId !== null) {
      socket.emit('employee_connected', currentActionId);
    }

    socket.on('refresh', async ({ actionId, existingGift, spot }) => {
      setGift(false);
      setRefresh(true);
      setTimeout(() => setRefresh(false), 100);
      dispatch(getEmployeeProfile({ id: actionId }));
      if (spot === spotId) setGift(existingGift);
    });
  }, [currentActionId, spotId, dispatch]);

  return Object.values(employeeProfile)?.length > 0 ? (
    <div className={css.wrapper}>
      <Modal
        onOpen={isModalShown}
        text={content[selectedLang].confirm}
        onClose={() => setIsModalShown(false)}
        onClick={handleLogout}
      />
      <button
        type="button"
        onClick={() => setIsModalShown(true)}
        title="logout"
        className={css.link}
      >
        <Exit /> {content[selectedLang].exit}
      </button>
      <p className={css.header}>{content[selectedLang].actions}</p>
      <Select
        options={getActionArray(employeeActions)}
        onSelect={id => actionSelectHandler(id)}
      />
      <p className={css.location}>
        <MdLocationPin /> {adress}
      </p>
      <p className={css.name}>
        {content[selectedLang].name}{' '}
        <span className={css.accent}>{employee}</span>
      </p>
      <ShowQr
        code={code}
        quantity={quantity}
        spotId={spotId}
        refresh={refresh}
        lang={selectedLang}
      />
      {gift && (
        <p className={css.gift}>
          <Info />
          {content[selectedLang].exist}
        </p>
      )}
      <div className={css.container}>
        <p className={css.title}>{content[selectedLang].history}</p>
        {history?.length > 0 ? (
          <div className={css.listWrapper}>
            <ul className={css.icons}>
              <li className={css.icon}>
                <Man />
              </li>
              <li
                className={css.icon}
                style={{ marginRight: 'auto', marginLeft: 'auto' }}
              >
                <Time />
              </li>
              <li className={css.icon}>
                <FiShoppingCart />
              </li>
            </ul>
            <ul className={css.list}>
              {history?.map(({ customer, time, amount }, index) => (
                <li className={css.item} key={index}>
                  <span
                    style={{
                      textAlign: 'center',
                      minWidth: '20px',
                    }}
                  >
                    {customer}
                  </span>
                  <span
                    style={{
                      width: '20%',
                      marginLeft: 'auto',
                      marginRight: '10%',
                      textAlign: 'center',
                    }}
                  >
                    {isoDateFormatter(lang, time)}
                  </span>
                  <span
                    style={{
                      width: '30%',
                      textAlign: 'right',
                      fontWeight: '900',
                    }}
                  >
                    {amount === 0 ? <FaGift /> : amount}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <p className={css.absent}>
            <Empty /> {content[selectedLang].absent}
          </p>
        )}
        <div className={css.overlay}></div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default EmployeeProfile;
