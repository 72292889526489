import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { Loader } from 'components/Loader/Loader';
import { checkActionPromo } from '../redux/slices/actions/operations';
import { setAuthHeader } from 'api';
import css from './CheckPromo.module.scss';

const content = {
  ru: {
    success: 'Успех',
    valid: 'QR-код действительный',
    gift: 'Предоставьте подарок вашему клиенту',
    invalid: 'Ссылка недействительна!!!',
    back: 'назад',
  },
  uk: {
    success: 'Успіх',
    valid: 'QR-код дійсний',
    gift: 'Надайте подарунок вашому клієнту',
    invalid: 'Посилання недійсне!!!',
    back: 'назад',
  },
  en: {
    success: 'Success',
    valid: 'QR code is valid',
    gift: 'Provide a gift to your customer',
    invalid: 'Link is invalid!!!',
    back: 'back',
  },
};

const CheckPromo = () => {
  const { id = '', promo = '' } = useParams();

  const dispatch = useDispatch();

  const { actionPromo, employeeToken } = useSelector(state => state.actions);

  useEffect(() => {
    if (id && promo && employeeToken) {
      setAuthHeader(employeeToken);
      dispatch(checkActionPromo({ id, promo }));
    }
  }, [id, promo, employeeToken, dispatch]);

  return Object.values(actionPromo).length > 0 ? (
    <div className={`${css.wrapper} ${css.check}`}>
      {actionPromo.valid ? (
        <div className={css.animate}>
          <svg
            width="100"
            height="100"
            viewBox="9 14 30 10"
            fill="none"
            stroke="#64C404"
            stroke-width="4"
            stroke-dasharray="100"
            strokeLinecap="round"
          >
            <path id="check" d="M 12,22 L 22,31 L 36,13" stroke-dashoffset="0">
              <animate
                attributeName="stroke-dashoffset"
                from="100"
                to="0"
                dur="3.5s"
              ></animate>
            </path>
          </svg>
          <span className={css.shadow}></span>
          <p className={css.title}>{content[actionPromo.lang].valid}</p>
          <p className={css.info}>{content[actionPromo.lang].code}</p>
          <p className={css.codeTitle}>{content[actionPromo.lang].gift}</p>
        </div>
      ) : (
        <p className={css.invalid}>{content[actionPromo.lang].invalid}</p>
      )}
      <Link className={css.link} to="/employees/profile">
        {content[actionPromo.lang].back}
      </Link>
    </div>
  ) : (
    <Loader />
  );
};

export default CheckPromo;
