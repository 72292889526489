import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Popup } from 'components/Popup/Popup';
import { Loader } from 'components/Loader/Loader';
import { RestrictedRoute } from 'components/restrictedRoute';
import { PrivateRoute } from 'components/privateRoute';
import { AuthRoute } from 'components/AuthRoute';
import Register from 'pages/Register';
import RegisterSuccess from 'pages/RegisterSuccess';
import Login from 'pages/Login';
import Verify from 'pages/Verify';
import Restore from 'pages/Restore';
import ShowPromo from 'pages/ShowPromo.jsx';
import NotFound from 'pages/NotFound';
import Home from 'pages/Home';
import Promo from 'pages/Promo';
import CheckPromo from 'pages/CheckPromo';
import Profile from 'pages/Profile';
import Actions from 'pages/Actions';
import Action from 'pages/Action';
import Spots from 'pages/Spots';
import Customer from 'pages/Customer';
import Employees from 'pages/Employees';
import Payment from 'pages/Payment';
import PaymentSuccess from 'pages/PaymentSuccess';
import PaymentFail from 'pages/PaymentFail';
import EmployeeProfile from 'pages/EmployeeProfile';
import CheckRestore from 'pages/CheckRestore';
import ResetPassword from 'pages/ResetPassword';
import Stats from 'pages/Stats';
import { SharedLayout } from 'components/SharedLayout/SharedLayout';
// import { setAuthHeader } from '../../api.js';
import { logout, setError } from '../../redux/slices/actions/slice.js';
import { logout as commonLogout } from '../../redux/slices/auth/operations';
import { setAuthError } from '../../redux/slices/auth/slice.js';
import socket from '../../socket';
import css from './App.module.scss';

export const App = () => {
  const { isLoading, error, isLoggedIn } = useSelector(state => state.auth);
  const actionLoading = useSelector(state => state.actions.isLoading);
  const actionError = useSelector(state => state.actions.error);
  const actionLoggedIn = useSelector(state => state.actions.isLoggedIn);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn || !actionLoggedIn) {
      dispatch(commonLogout());
    }
  }, [isLoggedIn, actionLoggedIn, dispatch]);

  useEffect(() => {
    if (error || actionError) {
      dispatch(logout());
      dispatch(setError(false));
      dispatch(setAuthError(false));
      setTimeout(() => navigate('/login'), 100);
    }
  }, [error, actionError, dispatch, navigate]);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Socket connected');
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div className={css.container}>
      <Popup />
      {(isLoading || actionLoading) && <Loader />}
      <Routes>
        {/* <Route index element={<Home />} /> */}
        <Route
          path="/register"
          element={<RestrictedRoute redirectTo="/" component={<Register />} />}
          // element={<Register />}
        />
        <Route
          path="/register/success"
          element={
            <RestrictedRoute redirectTo="/" component={<RegisterSuccess />} />
          }
          // element={<RegisterSuccess />}
        />
        <Route
          path="/login"
          element={<RestrictedRoute redirectTo="/" component={<Login />} />}
          // element={<Login />}
        />
        <Route
          path="/restore"
          element={<RestrictedRoute redirectTo="/" component={<Restore />} />}
          // element={<Restore />}
        />
        <Route
          path="/users/restore/:restoreKey"
          element={
            <RestrictedRoute redirectTo="/" component={<CheckRestore />} />
          }
          // element={<CheckRestore />}
        />
        <Route
          path="/users/restore/resetPassword"
          element={
            <RestrictedRoute redirectTo="/" component={<ResetPassword />} />
          }
          // element={<ResetPassword />}
        />
        <Route
          path="/employees/profile"
          element={<AuthRoute component={<EmployeeProfile />} />}
          // element={<EmployeeProfile />}
        />
        <Route
          path="/customer/:code/:spotId/:quantity"
          // path="/customer"
          // element={<AuthRoute component={<Customer />} />}
          element={<Customer />}
        />
        <Route
          path="/actions/:id/checkpromo/:promo"
          // element={<AuthRoute component={<Promo />} />}
          element={<Promo />}
        />
        <Route path="/actions/:id/showpromo/:promo" element={<ShowPromo />} />
        <Route
          path="/actions/:id/promo/:promo"
          element={<AuthRoute component={<CheckPromo />} />}
        />
        <Route path="/" element={<SharedLayout />}>
          <Route path="/" element={<Home />} />
          <Route
            path="/profile"
            element={<PrivateRoute component={<Profile />} />}
            // element={<Profile />}
          />
          <Route path="/users/verify/:token" element={<Verify />} />
          <Route
            path="/actions"
            element={<PrivateRoute component={<Actions />} />}
            // element={<Actions />}
          />
          <Route
            path="/actions/:id"
            element={<PrivateRoute component={<Action />} />}
            // element={<Action />}
          />
          <Route
            path="/actions/:id/stats"
            element={<PrivateRoute component={<Stats />} />}
            // element={<Stats />}
          />
          <Route
            path="/spots"
            element={<PrivateRoute component={<Spots />} />}
            // element={<Spots />}
          />
          <Route
            path="/employees"
            element={<PrivateRoute component={<Employees />} />}
            // element={<Employees />}
          />
          <Route
            path="/payment"
            element={<PrivateRoute component={<Payment />} />}
            // element={<Payment />}
          />
          <Route
            path="/successful-payment"
            element={<PrivateRoute component={<PaymentSuccess />} />}
            // element={<PaymentSuccess />}
          />
          <Route
            path="/failed-payment"
            element={<PrivateRoute component={<PaymentFail />} />}
            // element={<PaymentFail />}
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
};
