import { useState, useRef, useEffect } from 'react';
import css from './Section.module.scss';

export const Section = ({ children }) => {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        rootMargin: '0px',
        threshold: 0.4,
      }
    );

    const currentRef = sectionRef.current; // Store sectionRef.current in a variable

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className={`${css.section} ${isVisible ? css.visible : ''}`}
    >
      {children}
    </section>
  );
};
