import { useSelector } from 'react-redux';
import { MenuList } from 'components/MenuList/MenuList';
import { daysRemaining } from 'helpers/daysRemaining';
import css from './Action.module.scss';

const content = {
  ru: {
    remain: 'Осталось',
    days: 'бесплатного пользования!!!',
    paid: 'Вы оплатили пакет',
    remaining: 'Остается',
    time: 'дней пользования!!!',
  },
  uk: {
    remain: 'Залишилось',
    days: 'безкоштовного користування!!!',
    paid: 'Ви оплатили пакет',
    remaining: 'Залишається',
    time: 'днів користування!!!',
  },
  en: {
    remain: 'Remaining',
    days: 'of free usage!!!',
    paid: 'You have paid for the package',
    remaining: 'Remaining',
    time: 'days of usage!!!',
  },
};

const Profile = () => {
  const { lang } = useSelector(state => state.auth);
  const { createdAt, actions } = useSelector(state => state.auth.user);

  return (
    <>
      {actions.length > 0 && (
        <p className={css.notify}>
          {`${content[lang].remain} ${daysRemaining(createdAt)} ${
            content[lang].time
          }`}
        </p>
      )}
      <MenuList />
    </>
  );
};

export default Profile;
