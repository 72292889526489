import { useState, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Add } from 'icons/add.svg';
import { ReactComponent as Lamp } from 'icons/info.svg';
import { Select } from 'components/Select/Select';
import { getArray } from '../../helpers/getArray';
import { inputPattern } from '../../helpers/patterns';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { newSpot } from '../../redux/slices/auth/operations';
import css from './SpotForm.module.scss';

const content = {
  ru: {
    name: '* Название не должно содержать запрещенные символы',
    required: '* Обязательное поле',
    addSpot: 'добавить торговую точку',
    spotName: 'Название торговой точки',
    nameSpecify: 'Название торговой точки будет видно только вам',
    address: 'Адрес торговой точки',
    addressSpecify: 'Адрес торговой точки будет виден всем',
    employees: 'Сотрудники для этой торговой точки',
    noEmployees: 'У вас пока нет доступных сотрудников',
    addEmployee: 'добавить сотрудника',
    btn: 'Создать',
    placeholder: 'улица и номер дома',
  },
  uk: {
    name: '* Назва не повинна містити заборонені символи',
    required: "* Обов'язкове поле",
    addSpot: 'додати торгову точку',
    spotName: 'Назва торгової точки',
    nameSpecify: 'Назву торгової точки буде видно тільки вам',
    address: 'Адреса торгової точки',
    addressSpecify: 'Адресу торгової точки буде видно всім',
    employees: 'Співробітники для цієї торгової точки',
    noEmployees: 'У вас поки немає доступних співробітників',
    addEmployee: 'додати співробітника',
    btn: 'Створити',
    placeholder: 'вулиця та номер будинку',
  },
  en: {
    name: '* Name must not contain restricted characters',
    required: '* Required field',
    addSpot: 'add trade spot',
    spotName: 'Trade spot name',
    nameSpecify: 'Trade spot name will be visible only to you',
    address: 'Trade spot address',
    addressSpecify: 'Trade spot address will be visible to everyone',
    employees: 'Employees for this trade spot',
    noEmployees: "You don't have any available employees yet",
    addEmployee: 'add employee',
    btn: 'Create',
    placeholder: 'street and building number',
  },
};

export const SpotForm = () => {
  const [formValues, setFormValues] = useLocalStorage('spotForm', {
    name: '',
    adress: '',
  });
  const [isFormShown, setIsFormShown] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { employeesList } = useSelector(state => state.auth.user);
  const { lang } = useSelector(state => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formRef = useRef(null);

  const schema = yup.object().shape({
    name: yup
      .string()
      .matches(inputPattern, content[lang].name)
      .required(content[lang].required),
    adress: yup.string().required(content[lang].required),
  });

  const handleFormChange = e => {
    const { name, value } = e.target;

    setFormValues(prevValues => ({ ...prevValues, [name]: value }));
  };

  const openHandler = () => {
    setIsFormShown(state => !state);
    if (window.scrollY > 0) {
      setTimeout(() => {
        formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500);
    }
  };

  const submitHandler = async (values, { resetForm }) => {
    const { name, adress } = values;

    const { meta } = await dispatch(
      newSpot({
        name: name.trim().toLowerCase(),
        adress,
        employees: selectedEmployees,
      })
    );

    setSelectedEmployees([]);

    if (meta.requestStatus === 'fulfilled') {
      setSelectedEmployees([]);
      resetForm();
      localStorage.removeItem('spotForm');
      setFormValues({ name: '', adress: '' });
      setIsFormShown(false);
      setIsSubmitted(true);
      navigate('/spots');
    }
    window.scrollTo(0, 0);
  };

  return (
    <div className={css.wrapper}>
      <button className={css.btn} type="button" onClick={openHandler}>
        <Add /> {content[lang].addSpot}
      </button>
      <div className={isFormShown ? `${css.form} ${css.shown}` : `${css.form}`}>
        <Formik
          initialValues={formValues}
          validationSchema={schema}
          onSubmit={submitHandler}
        >
          {({ errors, touched, handleChange }) => (
            <Form autoComplete="off" ref={formRef}>
              <label className="name">
                <span className="title">{content[lang].spotName}</span>
                <Field
                  className="field"
                  name="name"
                  type="text"
                  onChange={e => {
                    handleChange(e);
                    handleFormChange(e);
                  }}
                />
                {errors.name && touched.name ? (
                  <div className={css.error}>{errors.name}</div>
                ) : (
                  <span className={css.detail}>
                    <Lamp />
                    {content[lang].nameSpecify}
                  </span>
                )}
              </label>
              <label className="name">
                <span className="title">{content[lang].address}</span>
                <Field
                  type="text"
                  name="adress"
                  className="field"
                  placeholder={content[lang].placeholder}
                  onChange={e => {
                    handleChange(e);
                    handleFormChange(e);
                  }}
                />
                {errors.adress && touched.adress ? (
                  <div className={css.error}>{errors.adress}</div>
                ) : (
                  <span className={css.detail}>
                    <Lamp /> {content[lang].addressSpecify}
                  </span>
                )}
              </label>
              <label className={css.select}>
                {content[lang].employees}
                {employeesList?.length > 0 ? (
                  <Select
                    width="200px"
                    initialValues={selectedEmployees}
                    options={getArray(employeesList)}
                    onSelect={values => setSelectedEmployees(values)}
                    multy={true}
                    onRefresh={isSubmitted}
                  />
                ) : (
                  <>
                    <p className={css.absent}>{content[lang].noEmployees}</p>
                    <Link
                      className={css.link}
                      to="/employees"
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      <Add />
                      {content[lang].addEmployee}
                    </Link>
                  </>
                )}
              </label>
              <button type="submit" className="btn">
                {content[lang].btn}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
