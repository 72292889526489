import { useState, useEffect } from 'react';
import { ReactComponent as Arrow } from 'icons/arrowdown.svg';
import css from './Select.module.scss';

export const Select = ({
  options,
  onSelect,
  multy = false,
  initialValues = null,
  onRefresh = false,
}) => {
  const [isListShown, setIsListShown] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    initialValues || multy ? [] : [options[0]?.value]
  );

  const getText = value => {
    if (!value) return;
    const option = options.find(item => item.value === value);
    if (option) return option.text;
    return '';
  };

  const getArrayText = array => {
    let text = '';
    array.forEach(item => (text += `${item} `));
    return text;
  };

  const handleListOpen = () => {
    setIsListShown(state => !state);
    // multy ? setIsListShown(state => !state) : setIsListShown(true);
  };

  const handleSelect = value => {
    setIsListShown(false);
    setSelectedOptions([value]);
    onSelect && onSelect([value]);
  };

  const handleRadioChange = e => {
    const newValue = e.target.value;

    const newCheckedValues = selectedOptions.includes(newValue)
      ? selectedOptions.filter(value => value !== newValue)
      : [...selectedOptions, newValue];

    setSelectedOptions(newCheckedValues);
    onSelect && onSelect(newCheckedValues);
  };

  useEffect(() => {
    if (initialValues?.length > 0) {
      setSelectedOptions(initialValues);
    }
  }, [initialValues]);

  useEffect(() => {
    if (onRefresh) setSelectedOptions([]);
  }, [onRefresh]);

  return (
    <div className={css.wrapper}>
      <button onClick={handleListOpen} className={css.btn} type="button">
        {multy && <span className={css.amount}>{selectedOptions.length}</span>}
        {multy ? getArrayText(selectedOptions) : getText(selectedOptions[0])}
        <Arrow />
      </button>
      <ul className={isListShown ? `${css.list} ${css.show}` : `${css.list}`}>
        {options.map(({ text = '', value = '' }, index) => (
          <li className={css.item} key={index}>
            {multy ? (
              <label className={css.option}>
                {text}
                <input
                  type="checkbox"
                  className={css.box}
                  value={text}
                  checked={selectedOptions.includes(text)}
                  onChange={value => handleRadioChange(value)}
                />
                <span className={css.checkbox}></span>
              </label>
            ) : (
              <button
                type="button"
                className={css.optionBtn}
                onClick={() => handleSelect(value)}
              >
                {text}
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
