import { useSelector } from 'react-redux';
import css from './PaymentSuccess.module.scss';

const content = {
  ru: { success: 'Оплата успешно произведена!' },
  uk: { success: 'Оплата успішно проведена!' },
  en: { success: 'Payment was successfully completed!' },
};

const PaymentSuccess = () => {
  const { lang } = useSelector(state => state.auth);

  return (
    <div className={css.container}>
      <div className={css.wrapper}>
        <svg
          width="100"
          height="100"
          viewBox="9 14 30 10"
          fill="none"
          stroke="#02e6ca"
          stroke-width="4"
          stroke-dasharray="100"
          strokeLinecap="round"
        >
          <path id="check" d="M 12,22 L 22,31 L 36,13" stroke-dashoffset="0">
            <animate
              attributeName="stroke-dashoffset"
              from="100"
              to="0"
              dur="3.5s"
            ></animate>
          </path>
        </svg>
      </div>
      <p className={css.text}>{content[lang].success}</p>
    </div>
  );
};

export default PaymentSuccess;
