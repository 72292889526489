import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Section } from '../components/Section/Section';
import ownerImage from '../icons/owner.jpg';
import scanImage from '../icons/code.jpg';
import scanGiftImage from '../icons/gift.jpg';
import { ReactComponent as Ok } from 'icons/ok.svg';
import { ReactComponent as Arrow } from 'icons/arrowright.svg';
import { content } from 'content/content';
import { getUserLang } from 'helpers/getUserLang';
import css from './Home.module.scss';

const Home = () => {
  const [ownerImageLoaded, setOwnerImageLoaded] = useState(false);
  const [scanImageLoaded, setScanImageLoaded] = useState(false);
  const [scanGiftImageLoaded, setScanGiftImageLoaded] = useState(false);

  const userLang = useSelector(state => state.auth.lang);
  const { isLoggedIn } = useSelector(state => state.auth);

  const lang = isLoggedIn ? userLang : getUserLang();

  const handleImageLoad = (src, setImageLoaded) => {
    const img = new Image();
    img.src = `${src}?${new Date().getTime()}`;
    if (img.complete) {
      setImageLoaded(true);
    } else {
      img.onload = () => setImageLoaded(true);
    }
  };

  useEffect(() => {
    handleImageLoad(ownerImage, setOwnerImageLoaded);
    handleImageLoad(scanImage, setScanImageLoaded);
    handleImageLoad(scanGiftImage, setScanGiftImageLoaded);
  }, []);

  return (
    <div className={css.wrapper}>
      <Section>
        <h1 className={css.header} style={{ marginLeft: 'auto' }}>
          {content[lang].title}
        </h1>
        <h3 className={css.header} style={{ marginLeft: 'auto' }}>
          {content[lang].secondTitle}
        </h3>
        {!ownerImageLoaded && (
          <div className={css.skeleton}>
            <Skeleton height="100%" width="100%" />
          </div>
        )}
        <img
          src={ownerImage}
          alt="owner"
          className={css.img}
          style={{
            marginBottom: '10px',
            display: ownerImageLoaded ? 'block' : 'none',
          }}
          loading="lazy"
          onLoad={() => setOwnerImageLoaded(true)}
        />
        <p className={css.action}>
          {content[lang].start}
          <br />
          {content[lang].middle}
          <br />
          {content[lang].example}
        </p>
        <h3 className={css.head}>{content[lang].use}</h3>
      </Section>
      <Section>
        <h3 className={css.title}>{content[lang].how}</h3>
        <div className={css.point}>
          <Ok />
          <p className={css.create}>{content[lang].stepOne}</p>
        </div>
      </Section>
      <Section>
        <div className={css.point}>
          <Ok />
          <p className={css.create}>{content[lang].stepTwo}</p>
        </div>
        {!scanImageLoaded && (
          <div className={css.skeleton}>
            <Skeleton height={300} />
          </div>
        )}
        <img
          src={scanImage}
          alt="scan"
          className={css.img}
          loading="lazy"
          style={{ display: scanImageLoaded ? 'block' : 'none' }}
          onLoad={() => setScanImageLoaded(true)}
        />
      </Section>
      <Section>
        <div className={css.point}>
          <Ok />
          <p className={css.create}>
            {content[lang].acc} <span>{content[lang].load}</span>{' '}
            {content[lang].can}
          </p>
        </div>
        {!scanGiftImageLoaded && (
          <div className={css.skeleton}>
            <Skeleton height={300} width="100%" />
          </div>
        )}
        <img
          src={scanGiftImage}
          alt="scan"
          className={css.img}
          loading="lazy"
          style={{ display: scanGiftImageLoaded ? 'block' : 'none' }}
          onLoad={() => setScanGiftImageLoaded(true)}
        />
      </Section>
      {/* Repeat similar sections with skeletons for other images as needed */}
      <Section>
        <h3 className={css.title}>{content[lang].advantageTitle}</h3>
        <div className={css.point}>
          <Ok />
          <p className={css.create}>{content[lang].advantageOne}</p>
        </div>
        <span className={css.highligth}>{content[lang].pointOne}</span>
        <span className={css.highligth}>{content[lang].pointTwo}</span>
        <span className={css.highligth}>{content[lang].pointThree}</span>
        <p className={css.advantage}>
          {content[lang].noticeOne}
          <br />
          <span className={css.client}>{content[lang].noticeTwo}</span>
        </p>
      </Section>
      <Section>
        <div className={css.point}>
          <Ok />
          <p className={css.create}>
            {content[lang].stats}
            <span className={css.stats}>{content[lang].amount}</span>
            <span className={css.stats}>{content[lang].participants}</span>
            <span className={css.stats}>{content[lang].gifts}</span>
            <span className={css.stats}>{content[lang].reviews}</span>
          </p>
        </div>
      </Section>
      <Section>
        <div className={css.point}>
          <Ok />
          <p className={css.create}>{content[lang].advantageTwo}</p>
        </div>
      </Section>
      <Section>
        <div className={css.point}>
          <Ok />
          <p className={css.create}>{content[lang].promo}</p>
        </div>
      </Section>
      <Section>
        <h3 className={css.title}>{content[lang].business}</h3>
        <div className={css.point} style={{ justifyContent: 'center' }}>
          <Ok />
          <p className={css.create}>{content[lang].coffee}</p>
        </div>
        <div className={css.point}>
          <Ok />
          <p className={css.create}>{content[lang].doner}</p>
        </div>
        <div className={css.point}>
          <Ok />
          <p className={css.create}>{content[lang].pizza}</p>
        </div>
        <div className={css.point}>
          <Ok />
          <p className={css.create}>{content[lang].smoke}</p>
        </div>
        <div className={css.point}>
          <Ok />
          <p className={css.create}>{content[lang].wash}</p>
        </div>
        <div className={css.point}>
          <Ok />
          <p className={css.create}>{content[lang].barber}</p>
        </div>
        <div className={css.point}>
          <Ok />
          <p className={css.create}>{content[lang].others}</p>
        </div>
      </Section>
      <Section>
        <div className={css.point}>
          <p className={css.check}>{content[lang].check}</p>
        </div>
      </Section>
      <Link to="/register" className={css.register}>
        {content[lang].register} <Arrow />
      </Link>
    </div>
  );
};

export default Home;
