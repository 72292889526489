import { useEffect, useState } from 'react';
import { HiOutlinePlus } from 'react-icons/hi';
import { HiMiniMinus } from 'react-icons/hi2';
import css from './Counter.module.scss';

export const Counter = ({ max, onSelect, onRefresh = false }) => {
  const [counter, setCounter] = useState(1);

  const minusHandler = () => {
    if (counter > 1) {
      setCounter(state => state - 1);
    }
  };

  const plusHandler = () => {
    if (counter < max) setCounter(state => state + 1);
  };

  useEffect(() => {
    if (onRefresh) setCounter(1);
  }, [onRefresh]);

  useEffect(() => {
    onSelect(counter);
  }, [counter, onSelect]);

  return (
    <div className={css.wrapper}>
      <button className={css.btn} type="button" onClick={minusHandler}>
        <HiMiniMinus />
      </button>
      <span className={css.counter}>{counter}</span>
      <button className={css.btn} type="button" onClick={plusHandler}>
        <HiOutlinePlus />
      </button>
    </div>
  );
};
