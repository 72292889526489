import { createSlice } from '@reduxjs/toolkit';
import {
  register,
  login,
  logout,
  verify,
  restorePassword,
  checkRestoreKey,
  resetPassword,
  refreshToken,
  deleteEmployee,
  deleteSpot,
  newEmployee,
  resendVerify,
  getAllEmployees,
  getAllSpots,
  newSpot,
  changeEmployees,
  getPayment,
} from './operations';

const initialState = {
  user: {},
  employees: [],
  spots: [],
  email: '',
  lang: null,
  isRegistered: false,
  isLoggedIn: false,
  isLoading: false,
  message: '',
  status: '',
  token: null,
  isInformed: false,
  freeSpots: null,
  error: false,
  isWarningShown: true,
  link: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setLang: (state, action) => {
      state.lang = action.payload;
    },
    setAuthError: (state, action) => {
      state.error = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setIsInformed: (state, action) => {
      state.isInformed = action.payload;
    },
    setIsWarningShown: (state, action) => {
      state.isWarningShown = action.payload;
    },
    setLink: (state, action) => {
      state.link = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(register.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isRegistered = true;
        state.lang = action.payload.lang;
        state.email = action.payload.email;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(login.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.lang = action.payload.lang;
        state.isLoggedIn = true;
        state.status = 'success';
        state.message = action.payload.message;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(verify.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(verify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.lang = action.payload.lang;
        state.isRegistered = false;
        state.isLoggedIn = true;
      })
      .addCase(verify.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(resendVerify.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(resendVerify.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.status = 'success';
      })
      .addCase(resendVerify.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(logout.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = {};
        state.employees = [];
        state.token = null;
        state.isLoggedIn = false;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
        if (action.payload.status === 401) {
          state.isLoggedIn = false;
        }
      })
      .addCase(resetPassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.status = 'success';
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(restorePassword.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(restorePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.message = action.payload.message;
        state.status = 'success';
      })
      .addCase(restorePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      })
      .addCase(checkRestoreKey.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(checkRestoreKey.fulfilled, (state, action) => {
        state.isLoading = false;
        state.email = action.payload.email;
      })
      .addCase(checkRestoreKey.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(refreshToken.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.employees = action.payload.employees;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
        if (action.payload?.status === 401) {
          state.error = true;
        }
      })
      .addCase(getAllEmployees.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllEmployees.fulfilled, (state, action) => {
        state.isLoading = false;
        state.employees = action.payload.employees;
      })
      .addCase(getAllEmployees.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
        if (action.payload.status === 401) {
          state.isLoggedIn = false;
        }
      })
      .addCase(getAllSpots.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllSpots.fulfilled, (state, action) => {
        state.isLoading = false;
        state.spots = action.payload.spots;
        state.freeSpots = action.payload.freeSpots;
      })
      .addCase(getAllSpots.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
        if (action.payload === 'Unauthorized') {
          state.isLoggedIn = false;
        }
      })
      .addCase(changeEmployees.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(changeEmployees.fulfilled, (state, action) => {
        state.user = {
          ...state.user,
          employeesList: action.payload.employeesList,
        };
        state.spots = action.payload.spots;
      })
      .addCase(changeEmployees.rejected, (state, action) => {
        state.message = action.payload;
        state.status = 'error';
        if (action.payload === 'Unauthorized') {
          state.isLoggedIn = false;
        }
      })
      .addCase(deleteEmployee.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.employees = action.payload.employees;
        state.user = {
          ...state.user,
          employeesList: action.payload.employeesList,
        };
        state.message = action.payload.message;
        state.status = 'success';
      })
      .addCase(deleteEmployee.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
        if (action.payload === 'Unauthorized') {
          state.isLoggedIn = false;
        }
      })
      .addCase(deleteSpot.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteSpot.fulfilled, (state, action) => {
        state.isLoading = false;
        state.spots = action.payload.spots;
        state.user = {
          ...state.user,
          spots: action.payload.spotsList,
          employeesList: action.payload.employeesList,
        };
        state.message = action.payload.message;
        state.status = 'success';
      })
      .addCase(deleteSpot.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
        if (action.payload === 'Unauthorized') {
          state.isLoggedIn = false;
        }
      })
      .addCase(getPayment.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getPayment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.link = action.payload.link;
      })
      .addCase(getPayment.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
        if (action.payload === 'Unauthorized') {
          state.isLoggedIn = false;
        }
      })
      .addCase(newEmployee.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(newEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.employees = action.payload.employees;
        state.user = {
          ...state.user,
          employeesList: action.payload.employeesList,
        };
        state.message = action.payload.message;
        state.status = 'success';
      })
      .addCase(newEmployee.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
        if (action.payload === 'Unauthorized') {
          state.isLoggedIn = false;
        }
      })
      .addCase(newSpot.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(newSpot.fulfilled, (state, action) => {
        state.isLoading = false;
        state.spots = action.payload.spots;
        state.freeSpots = action.payload.freeSpots;
        state.user = {
          ...state.user,
          spots: action.payload.spotsList,
          employeesList: action.payload.employeesList,
        };
        state.message = action.payload.message;
        state.status = 'success';
      })
      .addCase(newSpot.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
        if (action.payload === 'Unauthorized') {
          state.isLoggedIn = false;
        }
      });
  },
});

export const {
  setIsLoggedIn,
  setMessage,
  setStatus,
  setLang,
  setEmail,
  setIsInformed,
  setAuthError,
  setIsWarningShown,
  setLink,
} = authSlice.actions;
export const authReducer = authSlice.reducer;
