import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { getStats } from '../redux/slices/actions/operations';
import { Loader } from 'components/Loader/Loader';
import { Select } from 'components/Select/Select';
import { DateSelector } from 'components/DateSelector/DateSelector';
import { getArray } from 'helpers/getArray';
import { ReactComponent as Arrow } from 'icons/arrowback.svg';
import { extractDate } from 'helpers/extractDate';
import css from './Stats.module.scss';

const content = {
  ru: {
    all: 'все',
    today: 'сегодня',
    yesterday: 'вчера',
    date: 'выбрать дату',
    stats: 'Статистика акции:',
    created: 'Создана',
    period: 'Период времени:',
    spots: 'Торговые точки:',
    quantity: 'Количество участий в акции',
    gifts: 'Выдано подарков',
    users: 'Пользователей',
    unique: 'Уникальных пользователей',
  },
  uk: {
    all: 'всі',
    today: 'сьогодні',
    yesterday: 'вчора',
    date: 'вибрати дату',
    stats: 'Статистика акції:',
    created: 'Створена',
    period: 'Період часу:',
    spots: 'Торгові точки:',
    quantity: 'Кількість участі в акції',
    gifts: 'Видано подарунків',
    users: 'Користувачів',
    unique: 'Унікальних користувачів',
  },
  en: {
    all: 'all',
    today: 'today',
    yesterday: 'yesterday',
    date: 'select date',
    stats: 'Promotion Statistics:',
    created: 'Created',
    period: 'Time Period:',
    spots: 'Trading Spots:',
    quantity: 'Quantity of Participation',
    gifts: 'Gifts issued',
    users: 'Users',
    unique: 'Unique users',
  },
};

const Stats = () => {
  const { id = '' } = useParams();

  const [period, setPeriod] = useState('all');
  const [spot, setSpot] = useState('all');

  const { stats } = useSelector(state => state.actions);
  const { spots } = useSelector(state => state.auth.user);
  const { lang } = useSelector(state => state.auth);

  const {
    users = '',
    uniqueUsers = '',
    amount = '',
    createdAt = '',
    gifts = '',
  } = stats;

  const dispatch = useDispatch();

  const limit = new Date(createdAt);

  const options = [
    { text: content[lang].today, value: 'today' },
    { text: content[lang].yesterday, value: 'yesterday' },
    { text: content[lang].date, value: 'date' },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getStats({ id, period, spot }));
    }
  }, [id, period, spot, dispatch]);

  const selectPeriodHandler = value => {
    setPeriod(value);
  };

  const selectSpotHandler = value => {
    setSpot(value);
  };

  return Object.values(stats)?.length > 0 ? (
    <>
      <Link to={`/actions/${id}`} className="link" title="back">
        <Arrow />
      </Link>
      <div className={css.container}>
        <h3 className={css.header}>{content[lang].stats}</h3>
        <p className={css.date}>{`${content[lang].created} ${extractDate(
          createdAt
        )}`}</p>
        <p className={css.title}>{content[lang].period}</p>
        <Select options={options} onSelect={selectPeriodHandler} />
        {period === 'date' && (
          <DateSelector
            onSelect={value => setPeriod(value)}
            // initialDate={date}
            minDate={limit}
          />
        )}
        <p className={css.title}>{content[lang].spots}</p>
        <Select
          options={[{ text: content[lang].all, value: 'all' }].concat(
            getArray(spots)
          )}
          onSelect={selectSpotHandler}
        />
        <ul className={css.list}>
          <li className={css.item}>
            <span className={css.valueTitle}>{content[lang].quantity}</span>
            <span className={css.value}>{amount}</span>
          </li>
          <li className={css.item}>
            <span className={css.valueTitle}>{content[lang].gifts}</span>
            <span className={css.value}>{gifts}</span>
          </li>
          <li className={css.item}>
            <span className={css.valueTitle}>{content[lang].users}</span>
            <span className={css.value}>{users}</span>
          </li>
          <li className={css.item}>
            <span className={css.valueTitle}>{content[lang].unique}</span>
            <span className={css.value}>{uniqueUsers}</span>
          </li>
        </ul>
      </div>
    </>
  ) : (
    <Loader />
  );
};

export default Stats;
