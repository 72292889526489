import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SpotsList } from 'components/SpotsList/SpotsList';
import { SpotForm } from 'components/SpotForm/SpotForm';
import { ReactComponent as Arrow } from 'icons/arrowback.svg';
import { getAllSpots } from '../redux/slices/auth/operations';
import { setAuthHeader } from '../api.js';

const Spots = () => {
  const { spots, token } = useSelector(state => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      setAuthHeader(token);
      dispatch(getAllSpots());
    }
  }, [dispatch, token]);

  return (
    <>
      <Link to="/profile" className="link" title="back">
        <Arrow />
      </Link>
      <SpotsList list={spots} />
      <SpotForm />
    </>
  );
};

export default Spots;
