import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { ReactComponent as Statistics } from 'icons/statistics.svg';
import { MdDelete } from 'react-icons/md';
import { isoDateFormatter } from '../../helpers/isoDateFormatter';
import { extractDate } from '../../helpers/extractDate';
import { Select } from 'components/Select/Select';
import { DateSelector } from 'components/DateSelector/DateSelector';
import { ActionForm } from 'components/ActionForm/ActionForm';
import { Responses } from 'components/Responses/Responses';
import { Modal } from 'components/Modal/Modal';
import { FaGift } from 'react-icons/fa6';
import { LiaCopy } from 'react-icons/lia';
import { ReactComponent as Empty } from 'icons/empty.svg';
import { deleteAction } from '../../redux/slices/actions/operations';
import { setStatus, setMessage } from '../../redux/slices/auth/slice';
import { getArray } from 'helpers/getArray';
import css from './Details.module.scss';

const content = {
  ru: {
    today: 'сегодня',
    yesterday: 'вчера',
    date: 'выбрать дату',
    all: 'все',
    copy: 'Ссылка скопирована!',
    every: 'Каждый(-ая)',
    gift: 'в подарок!!!',
    action: 'Акция:',
    history: 'История акции за',
    user: 'пользователь',
    time: 'время',
    spot: 'торговая точка',
    spots: 'Торговые точки',
    amount: 'количество',
    absent: 'Пока нет истории',
    link: 'Ссылка на промо-код этой акции',
    promo:
      'Если вы хотите подарить одну единицу акционного товара, передайте эту ссылку',
    show: 'статистика акции',
    delete: 'удалить акцию',
    confirm: 'Вы уверенны, что хотите удалить эту акцию?',
    close: 'свернуть',
    showStory: 'показать всю историю',
  },
  uk: {
    today: 'сьогодні',
    yesterday: 'вчора',
    date: 'вибрати дату',
    all: 'всі',
    copy: 'Посилання скопійовано!',
    every: 'Кожен(-а)',
    gift: 'в подарунок!!!',
    action: 'Акція:',
    history: 'Історія акції за',
    user: 'користувач',
    time: 'час',
    spot: 'торгова точка',
    spots: 'Торгові точки',
    amount: 'кількість',
    absent: 'Поки немає історії',
    link: 'Посилання на промо-код цієї акції',
    promo:
      'Якщо ви хочете подарувати одиницю акційного товару, передайте це посилання',
    show: 'статистика акції',
    delete: 'видалити акцію',
    confirm: 'Ви впевнені, що хочете видалити цю акцію?',
    close: 'згорнути',
    showStory: 'показати всю історію',
  },
  en: {
    today: 'today',
    yesterday: 'yesterday',
    date: 'select date',
    all: 'all',
    copy: 'Link copied!',
    every: 'Every',
    gift: 'for free!!!',
    action: 'Action:',
    history: 'Action history for',
    user: 'user',
    time: 'time',
    spot: 'spot',
    spots: 'Trading spots',
    amount: 'amount',
    absent: 'No history yet',
    link: 'Link to this promotion code',
    promo:
      'If you want to gift one unit of promotional product, pass this link',
    show: 'promotion statistics',
    delete: 'delete promotion',
    confirm: 'Are you sure you want to delete this promotion?',
    close: 'close',
    showStory: 'show the whole history',
  },
};

export const Details = ({ action, selectedPeriod, selectedSpot }) => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [period, setPeriod] = useState(new Date());
  const [historyShown, setHistoryShown] = useState(false);

  const { lang } = useSelector(state => state.auth);
  const { spots } = useSelector(state => state.auth.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const textToCopy = useRef(null);

  const options = [
    { text: content[lang].today, value: 'today' },
    { text: content[lang].yesterday, value: 'yesterday' },
    { text: content[lang].date, value: 'date' },
  ];

  const {
    history = [],
    createdAt,
    _id,
    quantity = '',
    promo = '',
    product = '',
    responses = [],
  } = action;

  const limit = new Date(createdAt);
  const promoURL = `https://loyal2yourbusiness.com/actions/${_id}/showpromo/${promo}`;

  const selectPeriodHandler = value => {
    const time = value.join('');
    setPeriod(time);
    selectedPeriod(time);
  };

  const selectSpotHandler = value => {
    selectedSpot(value);
  };

  const copyToClipboard = () => {
    const input = textToCopy.current;
    input.type = 'text';
    input.select();
    document.execCommand('copy');
    dispatch(setMessage(content[lang].copy));
    dispatch(setStatus('success'));
  };

  const handleSelect = value => {
    const date = extractDate(value);
    selectedPeriod(date);
  };

  const deleteHandler = async () => {
    const { meta } = await dispatch(deleteAction({ id: _id }));

    if (meta.requestStatus === 'fulfilled') {
      setIsModalShown(false);
      localStorage.removeItem('actionForm');
      localStorage.removeItem('selectedSpots');
      navigate('/actions');
      window.scrollTo(0, 0);
    }
  };

  return (
    <div className={css.wrapper}>
      <h3 className={css.head}>{content[lang].action}</h3>
      <h3 className={css.head}>{`${
        content[lang].every
      } ${quantity} ${product?.toLowerCase()} ${content[lang].gift}`}</h3>
      <div className={css.container}>
        <p className={css.title}>{content[lang].history}</p>
        <Select options={options} onSelect={selectPeriodHandler} />
        {period === 'date' && (
          <DateSelector
            onSelect={handleSelect}
            // initialDate={date}
            minDate={limit}
          />
        )}
        <p className={css.title}>{content[lang].spots}</p>
        <Select
          options={[{ text: content[lang].all, value: 'all' }].concat(
            getArray(spots)
          )}
          onSelect={selectSpotHandler}
        />
        {history?.length > 0 ? (
          <>
            <ul className={css.icons}>
              <li className={css.iconTitle} style={{ marginLeft: '10px' }}>
                {content[lang].user}
              </li>
              <li className={css.iconTitle} style={{ marginLeft: '10px' }}>
                {content[lang].spot}
              </li>
              <li className={css.iconTitle} style={{ marginLeft: '30px' }}>
                {content[lang].time}
              </li>
              <li className={css.iconTitle}>{content[lang].amount}</li>
            </ul>
            <ul
              className={
                historyShown ? `${css.list} ${css.shown}` : `${css.list}`
              }
            >
              {history.map(({ customer, adress, time, amount }, index) => (
                <li className={css.item} key={index}>
                  <span className={css.name}>{customer}</span>
                  <span className={css.adress}>{adress}</span>
                  <span className={css.time}>
                    {isoDateFormatter(lang, time)}
                  </span>
                  <span className={css.amount}>
                    {amount === 0 ? <FaGift /> : amount}
                  </span>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <p className={css.absent}>
            <Empty /> {content[lang].absent}
          </p>
        )}
        {!historyShown && <div className={css.overlay}></div>}
      </div>
      {history?.length > 0 && (
        <button
          type="button"
          className={css.show}
          onClick={() => setHistoryShown(state => !state)}
        >
          {historyShown ? content[lang].close : content[lang].showStory}
        </button>
      )}
      <div className={css.promoContainer}>
        <p className={css.codeTitle}>{content[lang].link}</p>
        <label className={css.promoWrapper}>
          <span className={css.info}>{content[lang].promo}</span>
          <input
            className={css.promo}
            ref={textToCopy}
            value={promoURL}
            readOnly
          />
          <button
            type="button"
            className={css.copy}
            onClick={copyToClipboard}
            title="copy the promo link"
          >
            <LiaCopy />
          </button>
        </label>
      </div>
      <button
        type="button"
        className={css.delete}
        onClick={() => setIsModalShown(true)}
      >
        <MdDelete /> {content[lang].delete}
      </button>
      <Link className={css.stat} to={`/actions/${_id}/stats`}>
        <Statistics /> {content[lang].show}
      </Link>
      <Responses reviews={responses} />
      <ActionForm width={'100%'} isEditable={true} initialValues={action} />
      <Modal
        onOpen={isModalShown}
        onClose={() => setIsModalShown(false)}
        text={content[lang].confirm}
        onClick={deleteHandler}
      />
    </div>
  );
};
