import { createSlice } from '@reduxjs/toolkit';
import { getCustomer } from './operations';

const initialState = {
  customer: {},
  message: '',
  status: '',
  isLoading: false,
  success: true,
};

const customersSlice = createSlice({
  name: 'customers',
  initialState: initialState,
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getCustomer.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customer = action.payload.customer;
        state.success = true;
      })
      .addCase(getCustomer.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.status = 'error';
      });
  },
});

export const { setMessage, setStatus } = customersSlice.actions;
export const customersReducer = customersSlice.reducer;
