import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as Profile } from 'icons/profile.svg';
import { ReactComponent as Login } from 'icons/login.svg';
import { ReactComponent as Logout } from 'icons/logout.svg';
import { ReactComponent as Logo } from 'icons/logo.svg';
import { Container } from 'components/Container/Container';
import { Modal } from 'components/Modal/Modal';
import { logout } from '../../redux/slices/auth/operations';
import { getUserLang } from 'helpers/getUserLang';
import css from './Header.module.scss';

const content = {
  ru: {
    home: 'Главная',
    exit: 'Выйти',
    enter: 'Войти',
    confirm: 'Вы уверенны, что хотите выйти?',
  },
  uk: {
    home: 'Головна',
    exit: 'Вийти',
    enter: 'Увійти',
    confirm: 'Ви впевнені, що хочете вийти?',
  },
  en: {
    home: 'Home',
    exit: 'Logout',
    enter: 'Login',
    confirm: 'Are you sure that you want to logout?',
  },
};

export const Header = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);

  const { isLoggedIn, lang = '' } = useSelector(state => state.auth);
  const { _id } = useSelector(state => state.auth.user);

  const selectedLang = lang ? lang : getUserLang();

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout({ _id }));
    setIsModalShown(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`${css.head} ${isFixed ? css.fixed : ''}`}>
      <Container>
        <div className={css.wrapper}>
          <Modal
            onOpen={isModalShown}
            text={content[selectedLang].confirm}
            onClose={() => setIsModalShown(false)}
            onClick={handleLogout}
          />
          <Link className={css.home} to="/" title="home page">
            <Logo />
          </Link>
          {isLoggedIn ? (
            <>
              <Link className={css.link} to="/profile" title="your profile">
                <Profile />
              </Link>
              <button
                className={css.link}
                onClick={() => setIsModalShown(true)}
                title="logout"
              >
                <Logout />
                {/* {content[selectedLang].exit} */}
              </button>
            </>
          ) : (
            <Link className={css.link} to="/login" title="login">
              <Login />
              {/* {content[selectedLang].enter} */}
            </Link>
          )}
        </div>
      </Container>
    </header>
  );
};
