import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resendVerify } from '../redux/slices/auth/operations';
import css from './RegisterSuccess.module.scss';

const content = {
  ru: {
    success: 'Вы успешно зарегистрировались!!!',
    sent: 'На вашу почту отправлено письмо.',
    link: 'Перейдите по ссылке в письме для завершения регистрации.',
    fail: 'Не пришло письмо?',
    repeat: 'Отправить повторно',
  },
  uk: {
    success: 'Ви успішно зареєструвались!!!',
    sent: 'На вашу пошту відправлено лист.',
    link: 'Перейдіть за посиланням у листі для завершення реєстрації.',
    fail: 'Не прийшов лист?',
    repeat: 'Відправити повторно',
  },
  en: {
    success: 'You have successfully registered!!!',
    sent: 'We have sent you email.',
    link: 'Follow the link in email to finish registration.',
    fail: "Haven't received email?",
    repeat: 'Send again',
  },
};

const RegisterSuccess = () => {
  const [isSent, setIsSent] = useState(false);

  const { email, lang } = useSelector(state => state.auth);

  const dispatch = useDispatch();

  const handleResend = async () => {
    if (isSent) return;
    const { meta } = await dispatch(resendVerify(email));

    if (meta.requestStatus === 'fulfilled') {
      setIsSent(true);
    }
  };

  return (
    <div className={css.container}>
      <div className={css.wrapper}>
        <svg
          width="100"
          height="100"
          viewBox="9 14 30 10"
          fill="none"
          stroke="#02e6ca"
          stroke-width="4"
          stroke-dasharray="100"
          strokeLinecap="round"
        >
          <path id="check" d="M 12,22 L 22,31 L 36,13" stroke-dashoffset="0">
            <animate
              attributeName="stroke-dashoffset"
              from="100"
              to="0"
              dur="3.5s"
            ></animate>
          </path>
        </svg>
      </div>
      <p className={css.title}>{content[lang].success}</p>
      <p className={css.info}>
        {content[lang].sent} <br />
        {content[lang].link}
      </p>
      <p className={css.error}>{content[lang].fail}</p>
      <button
        type="button"
        className={`btn ${css.btn}`}
        onClick={handleResend}
        disabled={isSent}
      >
        {content[lang].repeat}
      </button>
    </div>
  );
};

export default RegisterSuccess;
