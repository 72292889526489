export const showDate = (lang, isoDate) => {
  const date = new Date(isoDate);
  const now = new Date();
  const timeDifference = now - date;

  const word = {
    ru: { today: 'сейчас', yestarday: 'вчера' },
    uk: { today: 'зараз', yestarday: 'вчора' },
    en: { today: 'now', yestarday: 'yestarday' },
  };

  // Check if the difference is less than one minute
  if (timeDifference < 60000) {
    // 60000 milliseconds = 1 minute
    return word[lang].today;
  } else if (
    date.getDate() === now.getDate() &&
    date.getMonth() === now.getMonth() &&
    date.getFullYear() === now.getFullYear()
  ) {
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    return `${hour}:${minute}`;
  } else {
    // Check if the date is yesterday
    const isYesterday =
      date.getDate() === now.getDate() - 1 &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear();
    if (isYesterday) {
      const hour = String(date.getHours()).padStart(2, '0');
      const minute = String(date.getMinutes()).padStart(2, '0');
      return `${word[lang].yestarday} ${hour}:${minute}`;
    } else {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
  }
};
